import coreApi from "../../lib/coreApi";

export default {
  signIn: (data) => {
    let url = `/user/login`;
    let result = coreApi.POST(url, data);
    return result;
  },
  getLoggedInUser: (id) => {
    let url = `/user/get/${id}`;
    let result = coreApi.GET(url);
    return result;
  },
};
