import coreApi from "../../lib/coreApi";

export default {
  addParticipant: (data) => {
    let url = `/participant/add`;
    let result = coreApi.POST(url, data);
    return result;
  },
  getParticipantsList: (data) => {
    let url = `/participant/list`;
    let result = coreApi.PUT(url, data);
    return result;
  },
  editParticipant: (data) => {
    let url = `/participant/edit`;
    let result = coreApi.POST(url, data);
    return result;
  },
  deleteParticipant: (data) => {
    let url = `/participant/delete`;
    let result = coreApi.PATCH(url, data);
    return result;
  },
  getCountryList: () => {
    let url = `/country/list`;
    let result = coreApi.GET(url);
    return result;
  },
  getCityList: (country) => {
    let url = `/country/${country}/cities`;
    let result = coreApi.GET(url);
    return result;
  },
  addMultipleParticipant: (data) => {
    let url = `/participant/addmultiple`;
    let result = coreApi.POST(url, data);
    return result;
  },
  fileUpload: async(data) => {
    let url = `/participant/fileupload`;
    try{
      let result = await coreApi.POST(url, data);
      if(result.status === 400){
        throw new Error(result.message);
      }
      return result;
    }
    catch(error){
      throw error
    }
  },
  fileExport: () => {
    let url = `/participant/export`;
    let result = coreApi.GET(url);
    return result;
  },
  checkParticipantId: (id) => {
    let url = `/participant/check/participantid/${id}`;
    let result = coreApi.GET(url);
    return result;
  },
  checkPhoneNumber: (phoneNumber) => {
    let url = `/participant/check/phonenumber/${phoneNumber}`;
    let result = coreApi.GET(url);
    return result;
  },
  checkEmailId: (email) => {
    let url = `/participant/check/email/${email}`;
    let result = coreApi.GET(url);
    return result;
  },
  getParticipant: (id) => {
    let url = `/participant/get/${id}`;
    let result = coreApi.GET(url);
    return result;
  },
  getParticipantGroups: (id) => {
    // console.log({ id });
    let url = `/participant/getparicipantgroups`;
    let result = coreApi.POST(url, id);
    return result;
  },
  getFilterData: (data) => {
    let url = `/participant/filter`;
    let result = coreApi.POST(url, data);
    return result;
  },
};
