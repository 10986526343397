import { put, takeLatest, call } from "redux-saga/effects";
import {
  SIGN_IN_ACTION,
  SIGN_IN_LOADING,
  SIGN_IN_SUCCESS,
  SIGN_IN_ERROR,
  LOGGED_IN_USER_ACTION,
  LOGGED_IN_USER_LOADING,
  LOGGED_IN_USER_SUCCESS,
  LOGGED_IN_USER_ERROR,
} from "./types";
import Api from "./api";

function* signInSaga(data) {
  yield put({ type: SIGN_IN_LOADING });

  try {
    // console.log("calllogin-----")
    let res = yield call(Api.signIn, data.payload);
    if (res.status) {
      yield put({ type: SIGN_IN_ERROR, payload: res.message });
    } else {
      yield put({ type: SIGN_IN_SUCCESS, payload: res.result });
      if (res.result.token) {
        localStorage.setItem("admin_token", res.result.token);
        localStorage.setItem("admin_refresh_token", res.result.refresh_token);
      }

      if (res.result.userId) {
        localStorage.setItem("admin_userId", res.result.userId);
      }

      data.history.push("/country/configuration");
    }
  } catch (error) {
    yield put({ type: SIGN_IN_ERROR, payload: error.message });
  }
}

function* loggedInUserSaga() {
  yield put({ type: LOGGED_IN_USER_LOADING });
  try {
    let res = yield call(Api.getLoggedInUser);

    yield put({ type: LOGGED_IN_USER_SUCCESS, payload: res });
  } catch (error) {
    yield put({ type: LOGGED_IN_USER_ERROR, payload: error.message });
  }
}

function* watchSignInAsync() {
  // console.log("watchSignInAsync")
  yield takeLatest(SIGN_IN_ACTION, signInSaga);
  yield takeLatest(LOGGED_IN_USER_ACTION, loggedInUserSaga);
}

export default watchSignInAsync;
