import { put, takeLatest, call } from "redux-saga/effects";
import {
  ADD_GROUP_ACTION,
  ADD_GROUP_LOADING,
  GROUP_LIST_LOADING,
  GROUP_LIST_SUCCESS,
  GROUP_LIST_ERROR,
  GROUP_LIST_ACTION,
  EDIT_GROUP_ACTION,
  EDIT_GROUP_LOADING,
  EDIT_GROUP_SUCCESS,
  EDIT_GROUP_ERROR,
  DELETE_GROUP_ACTION,
  DELETE_GROUP_ERROR,
  DELETE_GROUP_LOADING,
  DELETE_GROUP_SUCCESS,
  ADD_GROUP_SUCCESS,
  ADD_GROUP_ERROR,
  ALL_PARTICIPANT_LIST_ACTION,
  ALL_PARTICIPANT_LIST_LOADING,
  ALL_PARTICIPANT_LIST_SUCCESS,
  ALL_PARTICIPANT_LIST_ERROR,
  ALL_GROUP_LIST_LOADING,
  ALL_GROUP_LIST_ERROR,
  ALL_GROUP_LIST_SUCCESS,
  ALL_GROUP_LIST_ACTION,
  COPY_MEMBER_LOADING,
  COPY_MEMBER_SUCCESS,
  COPY_MEMBER_ERROR,
  COPY_MEMBER_ACTION,
  GET_GROUP_ACTION,
  GET_GROUP_LOADING,
  GET_GROUP_SUCCESS,
  GET_GROUP_ERROR,
  SEND_GLOBAL_NOTIFICATION_ACTION,
  SEND_GLOBAL_NOTIFICATION_LOADING,
  SEND_GLOBAL_NOTIFICATION_SUCCESS,
  SEND_GLOBAL_NOTIFICATION_ERROR,
} from "./types";
import Api from "./api";

function* addGroupSaga(data) {
  yield put({ type: ADD_GROUP_LOADING });
  try {
    let res = yield call(Api.addGroup, data.payload);

    yield put({ type: ADD_GROUP_SUCCESS, payload: res });
  } catch (error) {
    yield put({ type: ADD_GROUP_ERROR, payload: error.message });
  }
}

function* getGroupsListSaga(data) {
  yield put({ type: GROUP_LIST_LOADING });
  try {
    let res = yield call(Api.getGroupsList, data.payload);
    yield put({ type: GROUP_LIST_SUCCESS, payload: res });
  } catch (error) {
    yield put({ type: GROUP_LIST_ERROR, payload: error.message });
  }
}

function* getParticipantsListSaga(data) {
  yield put({ type: ALL_PARTICIPANT_LIST_LOADING });
  try {
    let res = yield call(Api.getAllParticipantsList, data.payload);
    yield put({ type: ALL_PARTICIPANT_LIST_SUCCESS, payload: res });
  } catch (error) {
    yield put({ type: ALL_PARTICIPANT_LIST_ERROR, payload: error.message });
  }
}

function* getAllGroupsListSaga(data) {
  yield put({ type: ALL_GROUP_LIST_LOADING });
  try {
    let res = yield call(Api.getAllGroupsList, data.payload);
    yield put({ type: ALL_GROUP_LIST_SUCCESS, payload: res });
  } catch (error) {
    yield put({ type: ALL_GROUP_LIST_ERROR, payload: error.message });
  }
}

function* editGroupSaga(data) {
  yield put({ type: EDIT_GROUP_LOADING });
  try {
    let res = yield call(Api.editGroup, data.payload);

    yield put({ type: EDIT_GROUP_SUCCESS, payload: res });
  } catch (error) {
    yield put({ type: EDIT_GROUP_ERROR, payload: error.message });
  }
}

function* deleteGroupSaga(data) {
  yield put({ type: DELETE_GROUP_LOADING });
  try {
    let res = yield call(Api.deleteGroup, data.payload);

    yield put({ type: DELETE_GROUP_SUCCESS, payload: res });
  } catch (error) {
    yield put({ type: DELETE_GROUP_ERROR, payload: error.message });
  }
}

function* copyMemberSaga(data) {
  yield put({ type: COPY_MEMBER_LOADING });
  try {
    let res = yield call(Api.copyMember, data.payload);

    yield put({ type: COPY_MEMBER_SUCCESS, payload: res });
  } catch (error) {
    yield put({ type: COPY_MEMBER_ERROR, payload: error.message });
  }
}

function* getGroupSaga(data) {
  yield put({ type: GET_GROUP_LOADING });
  try {
    let res = yield call(Api.getGroup, data.payload);

    yield put({ type: GET_GROUP_SUCCESS, payload: res });
  } catch (error) {
    yield put({ type: GET_GROUP_ERROR, payload: error.message });
  }
}

function* sendGlobalNotificationSaga(data) {
  yield put({ type: SEND_GLOBAL_NOTIFICATION_LOADING });
  try {
    let res = yield call(Api.sendGlobalNotification, data.payload);

    yield put({ type: SEND_GLOBAL_NOTIFICATION_SUCCESS, payload: res });
  } catch (error) {
    yield put({ type: SEND_GLOBAL_NOTIFICATION_ERROR, payload: error.message });
  }
}

function* watchGroupAsync() {
  yield takeLatest(ADD_GROUP_ACTION, addGroupSaga);
  yield takeLatest(GROUP_LIST_ACTION, getGroupsListSaga);
  yield takeLatest(ALL_PARTICIPANT_LIST_ACTION, getParticipantsListSaga);
  yield takeLatest(ALL_GROUP_LIST_ACTION, getAllGroupsListSaga);
  yield takeLatest(EDIT_GROUP_ACTION, editGroupSaga);
  yield takeLatest(DELETE_GROUP_ACTION, deleteGroupSaga);
  yield takeLatest(COPY_MEMBER_ACTION, copyMemberSaga);
  yield takeLatest(GET_GROUP_ACTION, getGroupSaga);
  yield takeLatest(SEND_GLOBAL_NOTIFICATION_ACTION, sendGlobalNotificationSaga);
}

export default watchGroupAsync;
