import createReducer from "../../reducers/createReducer";
import {
  ADD_GROUP_MEMBER_LOADING,
  ADD_GROUP_MEMBER_ERROR,
  ADD_GROUP_MEMBER_SUCCESS,
  DELETE_GROUP_MEMBER_LOADING,
  DELETE_GROUP_MEMBER_ERROR,
  DELETE_GROUP_MEMBER_SUCCESS,
  EDIT_GROUP_MEMBER_LOADING,
  EDIT_GROUP_MEMBER_ERROR,
  EDIT_GROUP_MEMBER_SUCCESS, 
  ALL_GROUP_MEMBER_LIST_LOADING,
  ALL_GROUP_MEMBER_LIST_ERROR,
  ALL_GROUP_MEMBER_LIST_SUCCESS,
  COPY_GROUP_MEMBER_LOADING,
  COPY_GROUP_MEMBER_SUCCESS,
  COPY_GROUP_MEMBER_ERROR,
  GROUP_MEMBER_LIST_ERROR,
  GROUP_MEMBER_LIST_LOADING,
  GROUP_MEMBER_LIST_SUCCESS,
  UPDATE_GROUP_MEMBER_LOADING,
  UPDATE_GROUP_MEMBER_ERROR,
  UPDATE_GROUP_MEMBER_SUCCESS,
  ALL_PARTICIPANT_LIST_MLOADING,
  ALL_PARTICIPANT_LIST_MSUCCESS,
  ALL_PARTICIPANT_LIST_MERROR
} from "./types";

let initialState = {
  loading: false,
  error: null,
  success: null,
  participantsList: null,
  allGroupList:null,
  groupsList: null,
  addGroupLoadnig: false,
  deleteGroupLoadnig: false,
  editGroupLoadnig: false
};

export const groupMembersReducer = createReducer(initialState, {
  [ADD_GROUP_MEMBER_LOADING](state, action) {
    return Object.assign({}, state, {
      loading: false,
      success: null,
      country: null,
      error: null,
      addGroupLoadnig: true
    });
  },
  [ADD_GROUP_MEMBER_ERROR](state, action) {
    return Object.assign({}, state, {
      loading: false,
      success: null,
      country: null,
      error: action.payload,
      addGroupLoadnig: false
    });
  },
  [ADD_GROUP_MEMBER_SUCCESS](state, action) {
    return Object.assign({}, state, {
      loading: false,
      success: action.payload,
      country: null,
      error: null,
      addGroupLoadnig: false
    });
  },
  [UPDATE_GROUP_MEMBER_LOADING](state, action) {
    return Object.assign({}, state, {
      loading: false,
      success: null,
      country: null,
      error: null,
    });
  },
  [UPDATE_GROUP_MEMBER_ERROR](state, action) {
    return Object.assign({}, state, {
      loading: false,
      success: null,
      country: null,
      error: action.payload,
    });
  },
  [UPDATE_GROUP_MEMBER_SUCCESS](state, action) {
    return Object.assign({}, state, {
      loading: false,
      success: action.payload,
      country: null,
      error: null,
    });
  },
  [EDIT_GROUP_MEMBER_LOADING](state, action) {
    return Object.assign({}, state, {
      loading: false,
      success: null,
      country: null,
      error: null,
      editGroupLoadnig: true
    });
  },
  [EDIT_GROUP_MEMBER_ERROR](state, action) {
    return Object.assign({}, state, {
      loading: false,
      success: null,
      country: null,
      error: action.payload,
      editGroupLoadnig: false
    });
  },
  [EDIT_GROUP_MEMBER_SUCCESS](state, action) {
    return Object.assign({}, state, {
      loading: false,
      success: action.payload,
      country: null,
      error: null,
      editGroupLoadnig: false
    });
  },
  [DELETE_GROUP_MEMBER_LOADING](state, action) {
    return Object.assign({}, state, {
      loading: false,
      success: null,
      country: null,
      error: null,
      deleteGroupLoadnig: true
    });
  },
  [DELETE_GROUP_MEMBER_ERROR](state, action) {
    return Object.assign({}, state, {
      loading: false,
      success: null,
      country: null,
      error: action.payload,
      deleteGroupLoadnig: false
    });
  },
  [DELETE_GROUP_MEMBER_SUCCESS](state, action) {
    return Object.assign({}, state, {
      loading: false,
      success: action.payload,
      country: null,
      error: null,
      deleteGroupLoadnig: false
    });
  },
  [GROUP_MEMBER_LIST_LOADING](state, action) {
    return Object.assign({}, state, {
      loading: true,
      groupsList: null,
      success: null,
      error: null,
    });
  },
  [GROUP_MEMBER_LIST_SUCCESS](state, action) {
    return Object.assign({}, state, {
      loading: false,
      groupsList: action.payload,
      success: null,
      error: null,
    });
  },
  [GROUP_MEMBER_LIST_ERROR](state, action) {
    return Object.assign({}, state, {
      loading: false,
      groupsList: null,
      success: null,
      error: action.payload,
    });
  },
  [ALL_PARTICIPANT_LIST_MLOADING](state, action) {
    return Object.assign({}, state, {
      loading: true,
      participantsList: null,
      success: null,
      error: null,
    });
  },
  [ALL_PARTICIPANT_LIST_MSUCCESS](state, action) {
    return Object.assign({}, state, {
      loading: false,
      participantsList: action.payload,
      success: null,
      error: null,
    });
  },
  [ALL_PARTICIPANT_LIST_MERROR](state, action) {
    return Object.assign({}, state, {
      loading: false,
      participantsList: null,
      success: null,
      error: action.payload,
    });
  },
  [ALL_GROUP_MEMBER_LIST_LOADING](state, action) {
    return Object.assign({}, state, {
      loading: true,
      success: null,
      error: null,
    });
  },
  [ALL_GROUP_MEMBER_LIST_SUCCESS](state, action) {
    return Object.assign({}, state, {
      loading: false,
      success: null,
      allGroupList: action.payload,
      error: null,
    });
  },
  [ALL_GROUP_MEMBER_LIST_ERROR](state, action) {
    return Object.assign({}, state, {
      loading: false,
      success: null,
      error: action.payload,
    });
  },
  [COPY_GROUP_MEMBER_LOADING](state, action) {
    return Object.assign({}, state, {
      loading: true,
      success: null,
      error: null,
    });
  },
  [COPY_GROUP_MEMBER_SUCCESS](state, action) {
    return Object.assign({}, state, {
      loading: false,
      success: action.payload,
      error: null,
    });
  },
  [COPY_GROUP_MEMBER_ERROR](state, action) {
    return Object.assign({}, state, {
      loading: false,
      success: null,
      error: action.payload,
    });
  },
});