import i18next from "i18next";
import Cookies from "universal-cookie";
import { initReactI18next } from "react-i18next";

//language files
import en from "./en.json";
import ar from "./ar.json";

const cookies = new Cookies();

const languageDetector = {
  type: "languageDetector",
  async: true,
  detect: async (cb) => {
    if (cookies.get("admin_language")) {
      if (cookies.get("admin_language") === "ar") {
        document.getElementById("MainComponent").setAttribute("dir", "rtl");
      } else {
        document.getElementById("MainComponent").setAttribute("dir", "ltr");
      }
      cb(cookies.get("admin_language"));
    } else {
      cb("en");
    }
  },
  init: () => {},
  cacheUserLanguage: () => {},
};

i18next.use(languageDetector).use(initReactI18next).init({
  fallbackLng: "en",
  resources: { en, ar },
});

export default i18next;
