import { put, takeLatest, call } from "redux-saga/effects";
import {
  SCHEDULE_POST_ACTION,
  SCHEDULE_POST_LOADING,
  SCHEDULE_POST_SUCCESS,
  SCHEDULE_POST_ERROR,
  SCHEDULE_POST_LIST_ACTION,
  SCHEDULE_POST_LIST_LOADING,
  SCHEDULE_POST_LIST_SUCCESS,
  SCHEDULE_POST_LIST_ERROR,
  SCHEDULE_POST_UPDATE_ACTION,
  SCHEDULE_POST_UPDATE_LOADING,
  SCHEDULE_POST_UPDATE_SUCCESS,
  SCHEDULE_POST_UPDATE_ERROR,
  SCHEDULE_POST_UPLOAD_FILE_ACTION,
  SCHEDULE_POST_UPLOAD_FILE_LOADING,
  SCHEDULE_POST_UPLOAD_FILE_SUCCESS,
  SCHEDULE_POST_UPLOAD_FILE_ERROR,
} from "./types";
import Api from "./api";

function* schedulePost(data) {
  yield put({ type: SCHEDULE_POST_LOADING });
  try {
    let res = yield call(Api.schedulePost, data.payload);

    yield put({ type: SCHEDULE_POST_SUCCESS, payload: res });
   
  } catch (error) {
    yield put({ type: SCHEDULE_POST_ERROR, payload: error.message });
  }
}

function* schedulePostList(data) {
  yield put({ type: SCHEDULE_POST_LIST_LOADING });
  try {
    let res = yield call(Api.schedulePostList, data.payload);
    // console.log("schedulePostList---",res)
    // if (res?.result?.data && res.result.data.length) {
    yield put({ type: SCHEDULE_POST_LIST_SUCCESS, payload: res.result });
    // }
    // else {
    // yield put({ type: SCHEDULE_POST_LIST_ERROR, payload: res.message });
    // }
  } catch (error) {
    // console.log("schedulePostList error---",error)

    yield put({ type: SCHEDULE_POST_LIST_ERROR, payload: error.message });
  }
}

function* schedulePostUpdate(data) {
  yield put({ type: SCHEDULE_POST_UPDATE_LOADING });
  try {
    let res = yield call(Api.schedulePostUpdate, data.payload);

    yield put({ type: SCHEDULE_POST_UPDATE_SUCCESS, payload: res });
  } catch (error) {
    yield put({ type: SCHEDULE_POST_UPDATE_ERROR, payload: error.message });
  }
}

function* schedulePostUploadFile(data) {
  yield put({ type: SCHEDULE_POST_UPLOAD_FILE_LOADING });
  try {
    let res = yield call(Api.schedulePostUploadFile, data.payload);

    yield put({ type: SCHEDULE_POST_UPLOAD_FILE_SUCCESS, payload: res });
  } catch (error) {
    yield put({
      type: SCHEDULE_POST_UPLOAD_FILE_ERROR,
      payload: error.message,
    });
  }
}

function* watchSchedulePostAsync() {
  yield takeLatest(SCHEDULE_POST_ACTION, schedulePost);
  yield takeLatest(SCHEDULE_POST_LIST_ACTION, schedulePostList);
  yield takeLatest(SCHEDULE_POST_UPDATE_ACTION, schedulePostUpdate);
  yield takeLatest(SCHEDULE_POST_UPLOAD_FILE_ACTION, schedulePostUploadFile);
}

export default watchSchedulePostAsync;
