import React from "react";
import { useTranslation } from "react-i18next";
import { BrowserRouter as Router, Route, Switch, useLocation } from "react-router-dom";
import store from "./store";
import { Provider } from "react-redux";
import { Loading } from "./components/common";
import "./lib/i18n";
// import { renderRoutes } from 'react-router-config';
import "./App.scss";

const loading = () => <Loading />;

// Containers
const DefaultLayout = React.lazy(() => import("./containers/DefaultLayout"));

// Pages
const Login = React.lazy(() => import("./view/Login"));
const Page404 = React.lazy(() => import("./view/Page404"));
const Page500 = React.lazy(() => import("./view/Page500"));

const App = () => {
  const { t } = useTranslation();

  return (
    <Provider store={store}>
      <Router>
        <React.Suspense fallback={loading()}>
          <Switch>
            <Route
              path="/"
              name={t("Home")}
              component={(props) => {
                return localStorage.getItem("admin_token") ? (
                  <DefaultLayout {...props} />
                ) : (
                  <Login {...props} />
                );
              }}
            />
            <Route
              exact
              path="/login"
              name={t("Login Page")}
              render={(props) => <Login {...props} />}
            />
            <Route
              exact
              path="/404"
              name={t("Page 404")}
              render={(props) => <Page404 {...props} />}
            />
            <Route
              exact
              path="/500"
              name={t("Page 500")}
              render={(props) => <Page500 {...props} />}
            />
          </Switch>
        </React.Suspense>
      </Router>
    </Provider>
  );
};

export default App;
