import React from "react";
import {
  Input,
  Button,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  FormGroup,
  Label,
  FormFeedback,
  Spinner,
  InputGroupAddon,
  InputGroup,
  InputGroupText,
} from "reactstrap";
import ReactQuill from "react-quill";
import Select from "react-select";
import "react-quill/dist/quill.snow.css";
import "./style.css";

export const Loading = () => {
  return (
    <div className="loading-container">
      <Spinner color="primary" />
    </div>
  );
};

export function LoginInput({
  type,
  placeholder,
  id,
  value,
  onChange,
  invalid,
  errorMessage,
  width,
  height,
  disabled,
  checked,
  icon,
  iconOnRight
}) {
  // const checkBoxPref = {height: '30px', width: '30px'};
  return (
    <>
      <div
        style={{
          width: width ? width : null,
          height: height ? height : null,
          position: "relative",
        }}
      >
        <InputGroup className="mb-4">
          {!iconOnRight && icon && (
            <InputGroupAddon addonType="prepend">
              <InputGroupText>
                <i className={icon}></i>
              </InputGroupText>
            </InputGroupAddon>
          )}
          {/* {icon ? (
            <InputGroupAddon addonType="prepend">
              <InputGroupText>
                <i className={icon}></i>
              </InputGroupText>
            </InputGroupAddon>
          ) : null} */}
          <Input
            type={type}
            placeholder={placeholder}
            id={id}
            value={value}
            onChange={onChange}
            invalid={invalid}
            disabled={disabled}
            checked={checked}
            className={type === "checkbox" ? "table-checkbox" : ""}
          />
          {iconOnRight && icon && (
            <InputGroupAddon addonType="append">
              <InputGroupText>
                <i className={icon}></i>
              </InputGroupText>
            </InputGroupAddon>
          )}
          {errorMessage && invalid && (
            <FormFeedback>{errorMessage}</FormFeedback>
          )}
        </InputGroup>
      </div>
    </>
  );
}

export function CommonInput({
  type,
  placeholder,
  id,
  value,
  onChange,
  invalid,
  errorMessage,
  width,
  height,
  disabled,
  checked,
  postfix,
  onPostfixClick,
}) {
  // const checkBoxPref = {height: '30px', width: '30px'};
  return (
    <>
      <div
        style={{
          width: width ? width : null,
          height: height ? height : null,
          position: "relative",
        }}
      >
        <Input
          type={type}
          placeholder={placeholder}
          id={id}
          value={value}
          onChange={onChange}
          invalid={invalid}
          disabled={disabled}
          checked={checked}
          className={type === "checkbox" ? "table-checkbox" : ""}
        />
        {postfix ? (
          <div
            style={{
              position: "absolute",
              top: "20%",
              right: "15px",
              cursor: "pointer",
            }}
            onClick={onPostfixClick}
          >
            <i
              className={`fa fa-${postfix}`}
              style={{ color: "#00000050" }}
            ></i>
          </div>
        ) : null}
        {errorMessage && invalid && <FormFeedback>{errorMessage}</FormFeedback>}
      </div>
    </>
  );
}

export function FormInput({
  type,
  label,
  placeholder,
  value,
  onChange,
  invalid,
  errorMessage,
  prefix,
  prefixData,
  isRequired,
  ...props
}) {
  return prefix ? (
    <FormGroup>
      {label && (
        <Label>
          {label} {isRequired ? <span style={{ color: "red" }}>*</span> : null}
        </Label>
      )}
      <InputGroup>
        {prefixData ? prefixData : null}
        <Input
          type={type}
          value={value}
          placeholder={placeholder}
          onChange={onChange}
          invalid={invalid}
          {...props}
        />
        {errorMessage && invalid && <FormFeedback>{errorMessage}</FormFeedback>}
      </InputGroup>
    </FormGroup>
  ) : (
    <FormGroup>
      {label && (
        <Label>
          {label} {isRequired ? <span style={{ color: "red" }}>*</span> : null}
        </Label>
      )}
      <Input
        type={type}
        value={value}
        placeholder={placeholder}
        onChange={onChange}
        invalid={invalid}
        {...props}
      />
      {errorMessage && invalid && <FormFeedback>{errorMessage}</FormFeedback>}
    </FormGroup>
  );
}

export function SearchSelect({
  type,
  label,
  placeholder,
  value,
  onChange,
  invalid,
  errorMessage,
  prefix,
  prefixData,
  isRequired,
  options,
  isMulti,
  defaultValue,
  onInputChange,
  ...props
}) {
  const data = [];
  options.map((o) => {
    if (o) data.push(o);
    return o;
  });

  const customStyles = {
    indicatorSeparator: (base, state) => ({ ...base, display: "none" }),
    control: (base, state) => ({
      ...base,
      // state.isFocused can display different borderColor if you need it
      boxShadow: state.isFocused ? "0 0 0 0.2rem rgb(255 94 9 / 25%)" : "",
      borderColor: state.isFocused ? "#ffb189" : invalid ? "#f86c6b" : "#ddd",
      // overwrittes hover style
      "&:hover": {
        borderColor: state.isFocused ? "#ffb189" : invalid ? "#f86c6b" : "#ddd",
      },
    }),
  };

  const customStyles2 = {
    indicatorSeparator: (base, state) => ({ ...base, display: "none" }),
    control: (base, state) => ({
      ...base,
      // state.isFocused can display different borderColor if you need it
      boxShadow: state.isFocused ? "0 0 0 0.2rem rgb(255 94 9 / 25%)" : "",

      borderColor: state.isFocused ? "#ddd" : "#e4e7ea",
      // overwrittes hover style
      "&:hover": {
        borderColor: state.isFocused ? "#ddd" : "#e4e7ea",
      },
    }),
  };

  return (
    <FormGroup>
      {label && (
        <Label>
          {label} {isRequired ? <span style={{ color: "red" }}>*</span> : null}
        </Label>
      )}
      <Select
        placeholder={placeholder}
        className={"basic-single required"}
        classNamePrefix="select"
        defaultValue={defaultValue ? defaultValue : []}
        value={value}
        isClearable={true}
        onChange={onChange}
        // isDisabled={isDisabled}
        // isLoading={isLoading}
        // isClearable={isClearable}
        // isRtl={isRtl}
        // isSearchable={isSearchable}
        name="color"
        isMulti={isMulti}
        options={data ? data : null}
        styles={
          isRequired || (errorMessage && invalid) ? customStyles : customStyles2
        }
        onInputChange={onInputChange}
      />
      {errorMessage && invalid && (
        <div
          style={{
            width: "100%",
            marginTop: "0.25rem",
            fontSize: "80%",
            color: "#f86c6b",
          }}
        >
          {errorMessage}
        </div>
      )}
    </FormGroup>
  );
}

export function AsyncSearchSelect({
  type,
  label,
  placeholder,
  value,
  onChange,
  invalid,
  errorMessage,
  prefix,
  prefixData,
  isRequired,
  options,
  isMulti,
  defaultValue,
  onInputChange,
  ...props
}) {
  const data = [];

  for (const o of options) {
    if (o) {
      data.push(o);
    }
  }

  const customStyles = {
    control: (base, state) => ({
      ...base,
      // state.isFocused can display different borderColor if you need it
      borderColor: state.isFocused ? "#ddd" : invalid ? "#ddd" : "red",
      // overwrittes hover style
      "&:hover": {
        borderColor: state.isFocused ? "#ddd" : invalid ? "#ddd" : "red",
      },
    }),
  };

  const customStyles2 = {
    control: (base, state) => ({
      ...base,
      // state.isFocused can display different borderColor if you need it
      boxShadow: state.isFocused ? "0 0 0 0.2rem rgb(255 94 9 / 25%)" : "",

      borderColor: state.isFocused ? "#ddd" : "#e4e7ea",
      // overwrittes hover style
      "&:hover": {
        borderColor: state.isFocused ? "#ddd" : "#e4e7ea",
      },
    }),
  };

  return (
    <FormGroup>
      {label && (
        <Label>
          {label} {isRequired ? <span style={{ color: "red" }}>*</span> : null}
        </Label>
      )}
      <Select
        placeholder={placeholder}
        className={"basic-single required"}
        classNamePrefix="select"
        defaultValue={defaultValue ? defaultValue : []}
        value={value}
        onChange={onChange}
        // isDisabled={isDisabled}
        // isLoading={isLoading}
        // isClearable={isClearable}
        // isRtl={isRtl}
        // isSearchable={isSearchable}
        name="color"
        isMulti={isMulti}
        options={data ? data : null}
        styles={isRequired ? customStyles : customStyles2}
        onInputChange={onInputChange}
      />
      {errorMessage && invalid && <FormFeedback>{errorMessage}</FormFeedback>}
    </FormGroup>
  );
}

export function FormTextArea({
  type,
  label,
  placeholder,
  value,
  onChange,
  invalid,
  errorMessage,
}) {
  return (
    <FormGroup>
      {label && <Label>{label}</Label>}
      <Input
        type={"textarea"}
        value={value}
        placeholder={placeholder}
        onChange={onChange}
        invalid={invalid}
      />
      {errorMessage && invalid && <FormFeedback>{errorMessage}</FormFeedback>}
    </FormGroup>
  );
}

export function FormDatepicker({
  type,
  label,
  placeholder,
  value,
  onChange,
  invalid,
  errorMessage,
}) {
  return (
    <FormGroup>
      {label && <Label>{label}</Label>}
      <Input
        type={"date"}
        value={value}
        placeholder={placeholder}
        onChange={onChange}
        invalid={invalid}
      />
      {errorMessage && invalid && <FormFeedback>{errorMessage}</FormFeedback>}
    </FormGroup>
  );
}

export function FormEditor({
  value,
  onChange,
  invalid,
  errorMessage,
  label,
  className,
}) {
  return (
    <FormGroup>
      {label && <Label>{label}</Label>}
      <ReactQuill
        className={
          invalid ? `invalid-react-quill ${className}` : `${className}`
        }
        modules={{
          toolbar: [
            [{ header: "1" }, { header: "2" }, { font: [] }],
            [{ size: [] }],
            ["bold", "italic", "underline", "strike", "blockquote"],
            [
              { list: "ordered" },
              { list: "bullet" },
              { indent: "-1" },
              { indent: "+1" },
            ],
            [
              "align",
              "link",
              "image",
              "video",
              "background",
              "color",
              "code",
              "direction",
            ],
            ["clean"],
          ],
          clipboard: {
            matchVisual: false,
          },
        }}
        formats={[
          "header",
          "font",
          "size",
          "bold",
          "italic",
          "underline",
          "strike",
          "blockquote",
          "list",
          "bullet",
          "indent",
          "link",
          "image",
          "video",
          "background",
          "color",
          "code",
          "align",
          "direction",
        ]}
        value={value}
        onChange={onChange}
      />
      {errorMessage && invalid && <FormFeedback>{errorMessage}</FormFeedback>}
    </FormGroup>
  );
}

export function CommonDropdown({
  id,
  options,
  value,
  onChange,
  multiple,
  style,
  label,
}) {
  return (
    <FormGroup>
      {label && <Label>{label}</Label>}
      <Input
        type="select"
        id={id}
        value={value}
        onChange={onChange}
        multiple={multiple}
        style={style}
      >
        <option value="">Select</option>
        {options &&
          options.length &&
          options.map((data, index) => {
            return (
              <option key={index} value={data.label}>
                {data.label}
              </option>
            );
          })}
      </Input>
    </FormGroup>
  );
}

export function DeleteModal({ label, isOpen, onCancel, onSubmit }) {
  return (
    <Modal isOpen={isOpen} toggle={onCancel}>
      <ModalHeader toggle={onCancel}>
        {"Delete"} {label}
      </ModalHeader>
      <ModalBody>{"Are you sure ?"}</ModalBody>
      <ModalFooter>
        <Button color="primary" onClick={onSubmit}>
          {"Submit"}
        </Button>{" "}
        <Button color="secondary" onClick={onCancel}>
          {"Cancel"}
        </Button>
      </ModalFooter>
    </Modal>
  );
}
