import createReducer from "../../reducers/createReducer";
import {
  ALL_PARTICIPANT_LIST_POST_LOADING,
  ALL_PARTICIPANT_LIST_POST_SUCCESS,
  ALL_PARTICIPANT_LIST_POST_ERROR,
  ALL_GROUP_LIST_POST_LOADING,
  ALL_GROUP_LIST_POST_ERROR,
  ALL_GROUP_LIST_POST_SUCCESS,
  POST_LIST_LOADING,
  POST_LIST_ERROR,
  POST_LIST_SUCCESS,
  CHANGE_POST_STATUS_LOADING,
  CHANGE_POST_STATUS_SUCCESS,
  CHANGE_POST_STATUS_ERROR,
  DELETE_POST_LOADING,
  DELETE_POST_SUCCESS,
  DELETE_POST_ERROR,
  CLEAR_POST_REDUCER,
} from "./types";

let initialState = {
  loading: false,
  error: null,
  success: null,
  changeStatusSuccess: null,
  participantsList: null,
  allGroupList: null,
  postsList: null,
  addGroupLoadnig: false,
  deleteGroupLoadnig: false,
  editGroupLoadnig: false,
  group: null,
};

export const postReducer = createReducer(initialState, {
  [POST_LIST_LOADING](state, action) {
    return Object.assign({}, state, {
      loading: false,
      postsList: null,
      success: null,
      changeStatusSuccess: null,
      error: null,
    });
  },
  [POST_LIST_ERROR](state, action) {
    return Object.assign({}, state, {
      loading: false,
      postsList: null,
      success: null,
      error: action.payload,
    });
  },
  [POST_LIST_SUCCESS](state, action) {
    return Object.assign({}, state, {
      loading: false,
      postsList: action.payload,
      success: null,
      error: null,
    });
  },
  [ALL_PARTICIPANT_LIST_POST_LOADING](state, action) {
    return Object.assign({}, state, {
      loading: false,
      participantsList: null,
      success: null,
      error: null,
    });
  },
  [ALL_PARTICIPANT_LIST_POST_ERROR](state, action) {
    return Object.assign({}, state, {
      loading: false,
      participantsList: null,
      success: null,
      error: action.payload,
    });
  },
  [ALL_PARTICIPANT_LIST_POST_SUCCESS](state, action) {
    return Object.assign({}, state, {
      loading: false,
      participantsList: action.payload,
      success: null,
      error: null,
    });
  },
  [ALL_GROUP_LIST_POST_LOADING](state, action) {
    return Object.assign({}, state, {
      loading: true,
      success: null,
      error: null,
    });
  },
  [ALL_GROUP_LIST_POST_SUCCESS](state, action) {
    return Object.assign({}, state, {
      loading: false,
      success: null,
      allGroupList: action.payload,
      error: null,
    });
  },
  [ALL_GROUP_LIST_POST_ERROR](state, action) {
    return Object.assign({}, state, {
      loading: false,
      success: null,
      error: action.payload,
    });
  },
  [CHANGE_POST_STATUS_LOADING](state, action) {
    return Object.assign({}, state, {
      loading: true,
      changeStatusSuccess: null,
      error: null,
    });
  },
  [CHANGE_POST_STATUS_SUCCESS](state, action) {
    return Object.assign({}, state, {
      loading: false,
      changeStatusSuccess: action.payload,
      error: null,
    });
  },
  [CHANGE_POST_STATUS_ERROR](state, action) {
    return Object.assign({}, state, {
      loading: false,
      changeStatusSuccess: null,
      error: action.payload,
    });
  },
  [DELETE_POST_LOADING](state, action) {
    return Object.assign({}, state, {
      loading: false,
      success: null,
      error: null,
    });
  },
  [DELETE_POST_SUCCESS](state, action) {
    return Object.assign({}, state, {
      loading: false,
      success: action.payload,
      error: null,
    });
  },
  [DELETE_POST_ERROR](state, action) {
    return Object.assign({}, state, {
      loading: false,
      success: null,
      error: action.payload,
    });
  },
  [CLEAR_POST_REDUCER](state, action) {
    return Object.assign({}, state, {});
  },
});
