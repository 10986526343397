export const Constants = {
  // BASE_URL: "http://localhost:3001/api",
  // URL: "http://localhost:3001",
  // BASE_URL: "http://13.127.53.158/api",
  // URL: "http://13.127.53.158",

   BASE_URL: "https://happitude.3braintechnologies.com/api",
   URL: "https://happitude.3braintechnologies.com",

  STATUS: {
    ACTIVE: 1,
    INACTIVE: 0,
  },
  isValidPassword: new RegExp(
    /^(?=.*[a-zA-Z])(?=.*\d)(?=.*[!@#$%^&*()_+])[A-Za-z\d][A-Za-z\d!@#$%^&*()_+]{7,19}$/g
  ),
  isValidEmail: new RegExp(
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/g
  ),
  isPhoneNumber: new RegExp(/^[+]*[(]{0,1}[0-9]{1,4}[)]{0,1}[-\s./0-9]*$/g),
  isAlphaNumeric: new RegExp(/^[0-9a-zA-Z]+$/),

  ERROR: {
    EMAIL: {
      REQUIRED: "Email is required.",
      INVALID: "Please enter valid email.",
    },
    PASSWORD: {
      REQUIRED: "Password is required.",
      INVALID:
        "Your password at least 8 characters long and must contains minimum of 1 letter, 1 number, and 1 special character.",
    },
  },
  POST: {
    TYPE: {
      GENERAL: 1,
      ANNOUNCEMENT: 2,
      NEWS_LETTER: 3,
      ACTIVITY: 4,
    },
    STATUS: {
      ACTIVE: 1,
      INACTIVE: 0,
    },
  },
  VALID_IMAGE_FILES: [".png", ".jpg", ".jpeg", ".gif", ".bmp", ".tiff"],
  VALID_VIDEO_FILES: [".webm", ".mp4", ".ogg"],
  VALID_AUDIO_FILES: [".mp3", ".ogg"],
  VALID_TEXT_FILES: [".doc", ".docx", ".pdf"],
};
