import createReducer from "../../reducers/createReducer";
import {
  SCHEDULE_POST_LOADING,
  SCHEDULE_POST_SUCCESS,
  SCHEDULE_POST_ERROR,
  SCHEDULE_POST_LIST_LOADING,
  SCHEDULE_POST_LIST_SUCCESS,
  SCHEDULE_POST_LIST_ERROR,
  SCHEDULE_POST_UPDATE_LOADING,
  SCHEDULE_POST_UPDATE_SUCCESS,
  SCHEDULE_POST_UPDATE_ERROR,
  SCHEDULE_POST_UPLOAD_FILE_LOADING,
  SCHEDULE_POST_UPLOAD_FILE_SUCCESS,
  SCHEDULE_POST_UPLOAD_FILE_ERROR,
} from "./types";

let initialState = {
  loading: false,
  error: null,
  success: null,
  fileUploadSuccess: null,
  schedulePostList: [],
};

export const schedulePostReducer = createReducer(initialState, {
  [SCHEDULE_POST_LOADING](state, action) {
    return Object.assign({}, state, {
      loading: true,
      success: null,
      error: null,
    });
  },
  [SCHEDULE_POST_SUCCESS](state, action) {
    return Object.assign({}, state, {
      loading: false,
      success: action.payload,
      error: null,
    });
  },
  [SCHEDULE_POST_ERROR](state, action) {
    return Object.assign({}, state, {
      loading: false,
      success: null,
      error: action.payload,
    });
  },
  [SCHEDULE_POST_LIST_LOADING](state, action) {
    return Object.assign({}, state, {
      loading: false,
      success: null,
      fileUploadSuccess: null,
      error: null,
    });
  },
  [SCHEDULE_POST_LIST_SUCCESS](state, action) {
    if (
      parseInt(action.payload.page, 10) > 1 &&
      action.payload.data &&
      state?.schedulePostList?.data
    ) {
      action.payload.data = [
        ...state.schedulePostList.data,
        ...action.payload.data,
      ];
    }
    const updatedState = {
      ...state,
      loading: false,
      schedulePostList: action.payload,
      error: null,
    };
    return updatedState;
  },
  [SCHEDULE_POST_LIST_ERROR](state, action) {
    return Object.assign({}, state, {
      loading: false,
      schedulePostList: null,
      error: action.payload,
    });
  },
  [SCHEDULE_POST_UPDATE_LOADING](state, action) {
    return Object.assign({}, state, {
      loading: true,
      success: null,
      error: null,
    });
  },
  [SCHEDULE_POST_UPDATE_SUCCESS](state, action) {
    return Object.assign({}, state, {
      loading: false,
      success: action.payload,
      error: null,
    });
  },
  [SCHEDULE_POST_UPDATE_ERROR](state, action) {
    return Object.assign({}, state, {
      loading: false,
      success: null,
      error: action.payload,
    });
  },
  [SCHEDULE_POST_UPLOAD_FILE_LOADING](state, action) {
    return Object.assign({}, state, {
      loading: true,
      success: null,
      fileUploadSuccess: null,
      error: null,
    });
  },
  [SCHEDULE_POST_UPLOAD_FILE_SUCCESS](state, action) {
    return Object.assign({}, state, {
      loading: false,
      fileUploadSuccess: action.payload,
      error: null,
    });
  },
  [SCHEDULE_POST_UPLOAD_FILE_ERROR](state, action) {
    return Object.assign({}, state, {
      loading: false,
      fileUploadSuccess: null,
      error: action.payload,
    });
  },
});
