import coreApi from "../../lib/coreApi";

export default {
  getGroupsList: (data) => {
    let url = `/group/${data?.id}/members/list`;
    let result = coreApi.PUT(url, data);
    return result;
  },
  // getAllParticipantsList: (data) => {
  //   let url = `/group/allparticipants/list`;
  //   let result = coreApi.GET(url);
  //   return result;
  // },
  getAllGroupsList: () => {
    let url = `/group/list/all`;
    let result = coreApi.GET(url);
    return result;
  },
  copyMember: (data) => {
    let url = `/group/movemembers`;
    let result = coreApi.PUT(url, data);
    return result;
  },
  updateMember: (data) => {
    let url = `/group/updatemembers`;
    let result = coreApi.POST(url, data);
    return result;
  },
  deleteMember: (data) => {
    let url = `/group/deletemember`;
    let result = coreApi.PATCH(url, data);
    return result;
  },
};
