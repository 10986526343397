import createReducer from "../../reducers/createReducer";
import {
  ADD_GROUP_LOADING,
  GROUP_LIST_SUCCESS,
  ADD_GROUP_ERROR,
  ADD_GROUP_SUCCESS,
  DELETE_GROUP_LOADING,
  DELETE_GROUP_ERROR,
  DELETE_GROUP_SUCCESS,
  EDIT_GROUP_LOADING,
  EDIT_GROUP_ERROR,
  EDIT_GROUP_SUCCESS,
  GROUP_LIST_LOADING,
  GROUP_LIST_ERROR,
  ALL_PARTICIPANT_LIST_LOADING,
  ALL_PARTICIPANT_LIST_SUCCESS,
  ALL_PARTICIPANT_LIST_ERROR,
  ALL_GROUP_LIST_LOADING,
  ALL_GROUP_LIST_ERROR,
  ALL_GROUP_LIST_SUCCESS,
  COPY_MEMBER_LOADING,
  COPY_MEMBER_SUCCESS,
  COPY_MEMBER_ERROR,
  GET_GROUP_LOADING,
  GET_GROUP_ERROR,
  GET_GROUP_SUCCESS,
  SEND_GLOBAL_NOTIFICATION_LOADING,
  SEND_GLOBAL_NOTIFICATION_SUCCESS,
  SEND_GLOBAL_NOTIFICATION_ERROR,
  CLEAR_GROUP_REDUCER,
} from "./types";

let initialState = {
  loading: false,
  error: null,
  success: null,
  participantsList: null,
  allGroupList: null,
  groupsList: null,
  addGroupLoadnig: false,
  deleteGroupLoadnig: false,
  editGroupLoadnig: false,
  group: null,
};

export const groupReducer = createReducer(initialState, {
  [ADD_GROUP_LOADING](state, action) {
    return Object.assign({}, state, {
      loading: false,
      success: null,
      country: null,
      error: null,
      addGroupLoadnig: true,
    });
  },
  [ADD_GROUP_ERROR](state, action) {
    return Object.assign({}, state, {
      loading: false,
      success: null,
      country: null,
      error: action.payload,
      addGroupLoadnig: false,
    });
  },
  [ADD_GROUP_SUCCESS](state, action) {
    return Object.assign({}, state, {
      loading: false,
      success: action.payload,
      country: null,
      error: null,
      addGroupLoadnig: false,
    });
  },
  [EDIT_GROUP_LOADING](state, action) {
    return Object.assign({}, state, {
      loading: false,
      success: null,
      country: null,
      error: null,
      editGroupLoadnig: true,
    });
  },
  [EDIT_GROUP_ERROR](state, action) {
    return Object.assign({}, state, {
      loading: false,
      success: null,
      country: null,
      error: action.payload,
      editGroupLoadnig: false,
    });
  },
  [EDIT_GROUP_SUCCESS](state, action) {
    return Object.assign({}, state, {
      loading: false,
      success: action.payload,
      country: null,
      error: null,
      editGroupLoadnig: false,
    });
  },
  [GET_GROUP_LOADING](state, action) {
    return Object.assign({}, state, {
      loading: false,
      success: null,
      country: null,
      error: null,
    });
  },
  [GET_GROUP_ERROR](state, action) {
    return Object.assign({}, state, {
      loading: false,
      success: null,
      country: null,
      error: action.payload,
    });
  },
  [GET_GROUP_SUCCESS](state, action) {
    return Object.assign({}, state, {
      loading: false,
      success: null,
      country: null,
      error: null,
      group: action.payload,
    });
  },
  [DELETE_GROUP_LOADING](state, action) {
    return Object.assign({}, state, {
      loading: false,
      success: null,
      country: null,
      error: null,
      deleteGroupLoadnig: true,
    });
  },
  [DELETE_GROUP_ERROR](state, action) {
    return Object.assign({}, state, {
      loading: false,
      success: null,
      country: null,
      error: action.payload,
      deleteGroupLoadnig: false,
    });
  },
  [DELETE_GROUP_SUCCESS](state, action) {
    return Object.assign({}, state, {
      loading: false,
      success: action.payload,
      country: null,
      error: null,
      deleteGroupLoadnig: false,
    });
  },
  [GROUP_LIST_LOADING](state, action) {
    return Object.assign({}, state, {
      loading: true,
      groupsList: null,
      success: null,
      error: null,
    });
  },
  [GROUP_LIST_ERROR](state, action) {
    return Object.assign({}, state, {
      loading: false,
      groupsList: null,
      success: null,
      error: action.payload,
    });
  },
  [GROUP_LIST_SUCCESS](state, action) {
    return Object.assign({}, state, {
      loading: false,
      groupsList: action.payload,
      success: null,
      error: null,
    });
  },
  [ALL_PARTICIPANT_LIST_LOADING](state, action) {
    return Object.assign({}, state, {
      loading: false,
      participantsList: null,
      success: null,
      error: null,
    });
  },
  [ALL_PARTICIPANT_LIST_ERROR](state, action) {
    return Object.assign({}, state, {
      loading: false,
      participantsList: null,
      success: null,
      error: action.payload,
    });
  },
  [ALL_PARTICIPANT_LIST_SUCCESS](state, action) {
    return Object.assign({}, state, {
      loading: false,
      participantsList: action.payload,
      success: null,
      error: null,
    });
  },
  [ALL_GROUP_LIST_LOADING](state, action) {
    return Object.assign({}, state, {
      loading: true,
      success: null,
      error: null,
    });
  },
  [ALL_GROUP_LIST_SUCCESS](state, action) {
    return Object.assign({}, state, {
      loading: false,
      success: null,
      allGroupList: action.payload,
      error: null,
    });
  },
  [ALL_GROUP_LIST_ERROR](state, action) {
    return Object.assign({}, state, {
      loading: false,
      success: null,
      error: action.payload,
    });
  },
  [COPY_MEMBER_LOADING](state, action) {
    return Object.assign({}, state, {
      loading: true,
      success: null,
      error: null,
    });
  },
  [COPY_MEMBER_SUCCESS](state, action) {
    return Object.assign({}, state, {
      loading: false,
      success: action.payload,
      error: null,
    });
  },
  [COPY_MEMBER_ERROR](state, action) {
    return Object.assign({}, state, {
      loading: false,
      success: null,
      error: action.payload,
    });
  },
  [SEND_GLOBAL_NOTIFICATION_LOADING](state, action) {
    return Object.assign({}, state, {
      loading: true,
      success: null,
      error: null,
    });
  },
  [SEND_GLOBAL_NOTIFICATION_SUCCESS](state, action) {
    return Object.assign({}, state, {
      loading: false,
      success: action.payload,
      error: null,
    });
  },
  [SEND_GLOBAL_NOTIFICATION_ERROR](state, action) {
    return Object.assign({}, state, {
      loading: false,
      success: null,
      error: action.payload,
    });
  },
  [CLEAR_GROUP_REDUCER](state, action) {
    return Object.assign({}, state, {
      group: null,
    });
  },
});
