export const ADD_GROUP_ACTION = "ADD_GROUP_ACTION";
export const ADD_GROUP_LOADING = "ADD_GROUP_LOADING";
export const ADD_GROUP_SUCCESS = "ADD_GROUP_SUCCESS";
export const ADD_GROUP_ERROR = "ADD_GROUP_ERROR";

export const GROUP_LIST_ACTION = "GROUP_LIST_ACTION";
export const GROUP_LIST_LOADING = "GROUP_LIST_LOADING";
export const GROUP_LIST_SUCCESS = "GROUP_LIST_SUCCESS";
export const GROUP_LIST_ERROR = "GROUP_LIST_ERROR";

export const EDIT_GROUP_ACTION = "EDIT_GROUP_ACTION";
export const EDIT_GROUP_LOADING = "EDIT_GROUP_LOADING";
export const EDIT_GROUP_SUCCESS = "EDIT_GROUP_SUCCESS";
export const EDIT_GROUP_ERROR = "EDIT_GROUP_ERROR";

export const GET_GROUP_ACTION = "GET_GROUP_ACTION";
export const GET_GROUP_LOADING = "GET_GROUP_LOADING";
export const GET_GROUP_SUCCESS = "GET_GROUP_SUCCESS";
export const GET_GROUP_ERROR = "GET_GROUP_ERROR";

export const DELETE_GROUP_ACTION = "DELETE_GROUP_ACTION";
export const DELETE_GROUP_LOADING = "DELETE_GROUP_LOADING";
export const DELETE_GROUP_SUCCESS = "DELETE_GROUP_SUCCESS";
export const DELETE_GROUP_ERROR = "DELETE_GROUP_ERROR";

export const COPY_MEMBER_ACTION = "COPY_MEMBER_ACTION";
export const COPY_MEMBER_LOADING = "COPY_MEMBER_LOADING";
export const COPY_MEMBER_SUCCESS = "COPY_MEMBER_SUCCESS";
export const COPY_MEMBER_ERROR = "COPY_MEMBER_ERROR";

export const ALL_PARTICIPANT_LIST_ACTION = "ALL_PARTICIPANT_LIST_ACTION";
export const ALL_PARTICIPANT_LIST_LOADING = "ALL_PARTICIPANT_LIST_LOADING";
export const ALL_PARTICIPANT_LIST_SUCCESS = "ALL_PARTICIPANT_LIST_SUCCESS";
export const ALL_PARTICIPANT_LIST_ERROR = "ALL_PARTICIPANT_LIST_ERROR";

export const ALL_GROUP_LIST_ACTION = "ALL_GROUP_LIST_ACTION";
export const ALL_GROUP_LIST_LOADING = "ALL_GROUP_LIST_LOADING";
export const ALL_GROUP_LIST_SUCCESS = "ALL_GROUP_LIST_SUCCESS";
export const ALL_GROUP_LIST_ERROR = "ALL_GROUP_LIST_ERROR";

export const SEND_GLOBAL_NOTIFICATION_ACTION =
  "SEND_GLOBAL_NOTIFICATION_ACTION";
export const SEND_GLOBAL_NOTIFICATION_LOADING =
  "SEND_GLOBAL_NOTIFICATION_LOADING";
export const SEND_GLOBAL_NOTIFICATION_SUCCESS =
  "SEND_GLOBAL_NOTIFICATION_SUCCESS";
export const SEND_GLOBAL_NOTIFICATION_ERROR = "SEND_GLOBAL_NOTIFICATION_ERROR";

export const CLEAR_GROUP_REDUCER = "CLEAR_GROUP_REDUCER";
