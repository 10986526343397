export const ADD_GROUP_MEMBER_ACTION = "ADD_GROUP_MEMBER_ACTION";
export const ADD_GROUP_MEMBER_LOADING = "ADD_GROUP_MEMBER_LOADING";
export const ADD_GROUP_MEMBER_SUCCESS = "ADD_GROUP_MEMBER_SUCCESS";
export const ADD_GROUP_MEMBER_ERROR = "ADD_GROUP_MEMBER_ERROR";

export const GROUP_MEMBER_LIST_ACTION = "GROUP_MEMBER_LIST_ACTION";
export const GROUP_MEMBER_LIST_LOADING = "GROUP_MEMBER_LIST_LOADING";
export const GROUP_MEMBER_LIST_SUCCESS = "GROUP_MEMBER_LIST_SUCCESS";
export const GROUP_MEMBER_LIST_ERROR = "GROUP_MEMBER_LIST_ERROR";

export const EDIT_GROUP_MEMBER_ACTION = "EDIT_GROUP_MEMBER_ACTION";
export const EDIT_GROUP_MEMBER_LOADING = "EDIT_GROUP_MEMBER_LOADING";
export const EDIT_GROUP_MEMBER_SUCCESS = "EDIT_GROUP_MEMBER_SUCCESS";
export const EDIT_GROUP_MEMBER_ERROR = "EDIT_GROUP_MEMBER_ERROR";

export const UPDATE_GROUP_MEMBER_ACTION = "UPDATE_GROUP_MEMBER_ACTION";
export const UPDATE_GROUP_MEMBER_LOADING = "UPDATE_GROUP_MEMBER_LOADING";
export const UPDATE_GROUP_MEMBER_SUCCESS = "UPDATE_GROUP_MEMBER_SUCCESS";
export const UPDATE_GROUP_MEMBER_ERROR = "UPDATE_GROUP_MEMBER_ERROR";

export const DELETE_GROUP_MEMBER_ACTION = "DELETE_GROUP_MEMBER_ACTION";
export const DELETE_GROUP_MEMBER_LOADING = "DELETE_GROUP_MEMBER_LOADING";
export const DELETE_GROUP_MEMBER_SUCCESS = "DELETE_GROUP_MEMBER_SUCCESS";
export const DELETE_GROUP_MEMBER_ERROR = "DELETE_GROUP_MEMBER_ERROR";

export const COPY_GROUP_MEMBER_ACTION = "COPY_GROUP_MEMBER_ACTION";
export const COPY_GROUP_MEMBER_LOADING = "COPY_GROUP_MEMBER_LOADING";
export const COPY_GROUP_MEMBER_SUCCESS = "COPY_GROUP_MEMBER_SUCCESS";
export const COPY_GROUP_MEMBER_ERROR = "COPY_GROUP_MEMBER_ERROR";

export const ALL_PARTICIPANT_LIST_MACTION = "ALL_PARTICIPANT_LIST_ACTION";
export const ALL_PARTICIPANT_LIST_MLOADING = "ALL_PARTICIPANT_LIST_LOADING";
export const ALL_PARTICIPANT_LIST_MSUCCESS = "ALL_PARTICIPANT_LIST_SUCCESS";
export const ALL_PARTICIPANT_LIST_MERROR = "ALL_PARTICIPANT_LIST_ERROR";

export const ALL_GROUP_MEMBER_LIST_ACTION = "ALL_GROUP_MEMBER_LIST_ACTION";
export const ALL_GROUP_MEMBER_LIST_LOADING = "ALL_GROUP_MEMBER_LIST_LOADING";
export const ALL_GROUP_MEMBER_LIST_SUCCESS = "ALL_GROUP_MEMBER_LIST_SUCCESS";
export const ALL_GROUP_MEMBER_LIST_ERROR = "ALL_GROUP_MEMBER_LIST_ERROR";