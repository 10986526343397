import axios from "axios";
import { Constants } from "./constant";

const apiInstance = axios.create({
  baseURL: Constants.BASE_URL,
  headers: { "Content-Type": "application/json" },
  timeout: 1000 * 60 * 1.5,
});
// multipart/form-data
apiInstance.interceptors.request.use(
  async (config) => {
    let token = localStorage.getItem("admin_token");
    config.headers.Authorization = token ? `JWT ${token}` : "";

    if (config.data instanceof FormData) {
      config.headers['Content-Type'] = 'multipart/form-data';
    } else {
      config.headers['Content-Type'] = 'application/json';
    }
    
    return config;
  },
  (error) => {
    Promise.reject(error);
  }
);

apiInstance.interceptors.response.use(
  async function (response) {
    return response;
  },
  async function (error) {
    if (error.response.status === 401) {
      const originalRequest = error.response.config;
      let refreshToken = localStorage.getItem("admin_refresh_token");

      let token = localStorage.getItem("admin_token");
      if (refreshToken && !originalRequest._retry) {
        originalRequest._retry = true;
        let res = null;
        try {
          res = await refreshAccessToken(refreshToken);
          if (res.data.status !== 0) {
            await expireUserToken(token);
          }
        } catch (e) {
          await expireUserToken(token);
          // console.log("refresh token catch", e);
          return error.response;
        }
        const { data } = res;
        if (data.status === 0) {
          localStorage.setItem("admin_token", data.result.token);
          localStorage.setItem(
            "admin_refresh_token",
            data.result.refresh_token
          );
          return apiInstance(originalRequest);
        } else {
          await expireUserToken(token);
          return error.response;
        }
      } else {
        await expireUserToken(token);
        return error.response;
      }
    }

    if(error.response.status === 400) {
      return error.response.data
    }

    Promise.reject(error); 
  }
);

const refreshAccessToken = (token) => {
  // console.log("refreshAccessToken api call");
  return axios.get(Constants.BASE_URL + "/user/refreshToken", {
    headers: {
      Authorization: token ? `JWT ${token}` : "",
    },
  });
};

const expireUserToken = (isToken) =>
  new Promise(async (resolve, reject) => {
    let token = localStorage.getItem("admin_token");
    if (token && isToken) {
      localStorage.clear();
      if (process.env.NODE_ENV === "production") {
        window.open(`${Constants.URL}/login`, "_self");
      } else {
        window.open("http://localhost:3000/login", "_self");
      }
    } else {
      // console.log("user dont have any auth token");
    }
    resolve(true);
  });

export default apiInstance;
