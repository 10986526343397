import createReducer from "../../reducers/createReducer";
import {
  ADD_PARTICIPANT_LOADING,
  PARTICIPANT_LIST_SUCCESS,
  COUNTRY_LIST_LOADING,
  COUNTRY_LIST_SUCCESS,
  COUNTRY_LIST_ERROR,
  ADD_PARTICIPANT_ERROR,
  ADD_PARTICIPANT_SUCCESS,
  CITY_LIST_LOADING,
  CITY_LIST_SUCCESS,
  CITY_LIST_ERROR,
  DELETE_PARTICIPANT_LOADING,
  DELETE_PARTICIPANT_ERROR,
  DELETE_PARTICIPANT_SUCCESS,
  ADD_MULTIPLE_PARTICIPANT_LOADING,
  ADD_MULTIPLE_PARTICIPANT_ERROR,
  ADD_MULTIPLE_PARTICIPANT_SUCCESS,
  EDIT_PARTICIPANT_LOADING,
  EDIT_PARTICIPANT_ERROR,
  EDIT_PARTICIPANT_SUCCESS,
  FILE_UPLOAD_LOADING,
  FILE_UPLOAD_ERROR,
  FILE_UPLOAD_SUCCESS,
  CHECK_PARTICIPANT_ID_LOADING,
  CHECK_PARTICIPANT_ID_SUCCESS,
  CHECK_PARTICIPANT_ID_ERROR,
  GET_PARTICIPANT_LOADING,
  GET_PARTICIPANT_ERROR,
  GET_PARTICIPANT_SUCCESS,
  CHECK_PHONE_NUMBER_LOADING,
  CHECK_PHONE_NUMBER_SUCCESS,
  CHECK_PHONE_NUMBER_ERROR,
  CHECK_EMAIL_ID_LOADING,
  CHECK_EMAIL_ID_SUCCESS,
  CHECK_EMAIL_ID_ERROR,
  CLEAR_REDUCER_ACTION,
  FILE_EXPORT_LOADING,
  FILE_EXPORT_ERROR,
  FILE_EXPORT_SUCCESS,
  FILTER_DATA_LIST_LOADING,
  FILTER_DATA_LIST_ERROR,
  FILTER_DATA_LIST_SUCCESS,
  PARTICIPANT_GROUPS_LOADING,
  PARTICIPANT_GROUPS_SUCCESS,
  PARTICIPANT_GROUPS_ERROR,
} from "./types";
import { ALL_PARTICIPANT_LIST_MSUCCESS } from "../groupmembers/types";

let initialState = {
  loading: false,
  error: null,
  success: null,
  country: null,
  countryList: null,
  cityList: null,
  participantsList: null,
  addParticipantLoadnig: false,
  deleteParticipantLoadnig: false,
  editParticipantLoadnig: false,
  isPatricipantIdExist: false,
  isPhoneNumberExist: false,
  isEmailIdExist: false,
  participant: null,
  participantGroups: null,
  filteredData: null,
  fileUploadError: false,
};

export const participantReducer = createReducer(initialState, {
  [ADD_PARTICIPANT_LOADING](state, action) {
    return Object.assign({}, state, {
      loading: true,
      success: null,
      country: null,
      error: null,
      addParticipantLoadnig: true,
    });
  },
  [ADD_PARTICIPANT_ERROR](state, action) {
    return Object.assign({}, state, {
      loading: false,
      success: null,
      country: null,
      error: action.payload,
      addParticipantLoadnig: false,
    });
  },
  [ADD_PARTICIPANT_SUCCESS](state, action) {
    return Object.assign({}, state, {
      loading: false,
      success: action.payload,
      country: null,
      error: null,
      addParticipantLoadnig: false,
    });
  },

  [GET_PARTICIPANT_LOADING](state, action) {
    return Object.assign({}, state, {
      loading: true,
      success: null,
      country: null,
      error: null,
    });
  },
  [GET_PARTICIPANT_ERROR](state, action) {
    return Object.assign({}, state, {
      loading: false,
      success: null,
      country: null,
      error: action.payload,
    });
  },
  [GET_PARTICIPANT_SUCCESS](state, action) {
    return Object.assign({}, state, {
      loading: false,
      success: null,
      country: null,
      error: null,
      participant: action.payload,
    });
  },

  [EDIT_PARTICIPANT_LOADING](state, action) {
    return Object.assign({}, state, {
      loading: true,
      success: null,
      country: null,
      error: null,
    });
  },
  [EDIT_PARTICIPANT_ERROR](state, action) {
    return Object.assign({}, state, {
      loading: false,
      success: null,
      country: null,
      error: action.payload,
    });
  },
  [EDIT_PARTICIPANT_SUCCESS](state, action) {
    return Object.assign({}, state, {
      loading: false,
      success: action.payload,
      country: null,
      error: null,
    });
  },
  [FILE_UPLOAD_LOADING](state, action) {
    return Object.assign({}, state, {
      loading: false,
      success: null,
      country: null,
      error: null,
      addParticipantLoadnig: true,
    });
  },
  [FILE_UPLOAD_ERROR](state, action) {
    return Object.assign({}, state, {
      loading: false,
      success: null,
      country: null,
      fileUploadError: true,
      error: action.payload,
      addParticipantLoadnig: false,
    });
  },
  [FILE_UPLOAD_SUCCESS](state, action) {
    return Object.assign({}, state, {
      loading: false,
      success: action.payload,
      country: null,
      error: null,
      addParticipantLoadnig: false,
    });
  },
  [ALL_PARTICIPANT_LIST_MSUCCESS](state, action) {
    return Object.assign({}, state, {
      success: null,
    });
  },
  [FILE_EXPORT_LOADING](state, action) {
    return Object.assign({}, state, {
      loading: false,
      success: null,
      country: null,
      error: null,
    });
  },
  [FILE_EXPORT_ERROR](state, action) {
    return Object.assign({}, state, {
      loading: false,
      success: null,
      country: null,
      error: action.payload,
    });
  },
  [FILE_EXPORT_SUCCESS](state, action) {
    return Object.assign({}, state, {
      loading: false,
      success: action.payload,
      country: null,
      error: null,
    });
  },
  [EDIT_PARTICIPANT_LOADING](state, action) {
    return Object.assign({}, state, {
      loading: false,
      success: null,
      country: null,
      error: null,
      editParticipantLoadnig: true,
    });
  },
  [EDIT_PARTICIPANT_ERROR](state, action) {
    return Object.assign({}, state, {
      loading: false,
      success: null,
      country: null,
      error: action.payload,
      editParticipantLoadnig: false,
    });
  },
  [EDIT_PARTICIPANT_SUCCESS](state, action) {
    return Object.assign({}, state, {
      loading: false,
      success: action.payload,
      country: null,
      error: null,
      editParticipantLoadnig: false,
    });
  },
  [ADD_MULTIPLE_PARTICIPANT_LOADING](state, action) {
    return Object.assign({}, state, {
      loading: false,
      success: null,
      country: null,
      error: null,
      addParticipantLoadnig: true,
    });
  },
  [ADD_MULTIPLE_PARTICIPANT_ERROR](state, action) {
    return Object.assign({}, state, {
      loading: false,
      success: null,
      country: null,
      // error: action.payload,
      addParticipantLoadnig: false,
    });
  },
  [ADD_MULTIPLE_PARTICIPANT_SUCCESS](state, action) {
    return Object.assign({}, state, {
      loading: false,
      success: action.payload,
      country: null,
      error: null,
      addParticipantLoadnig: false,
    });
  },
  [DELETE_PARTICIPANT_LOADING](state, action) {
    return Object.assign({}, state, {
      loading: false,
      success: null,
      country: null,
      error: null,
      deleteParticipantLoadnig: true,
    });
  },
  [DELETE_PARTICIPANT_ERROR](state, action) {
    return Object.assign({}, state, {
      loading: false,
      success: null,
      country: null,
      error: action.payload,
      deleteParticipantLoadnig: false,
    });
  },
  [DELETE_PARTICIPANT_SUCCESS](state, action) {
    return Object.assign({}, state, {
      loading: false,
      success: action.payload,
      country: null,
      error: null,
      deleteParticipantLoadnig: false,
    });
  },
  [PARTICIPANT_LIST_SUCCESS](state, action) {
    return Object.assign({}, state, {
      loading: false,
      participantsList: action.payload,
      participant: null,
      success: null,
      error: null,
    });
  },
  [COUNTRY_LIST_LOADING](state, action) {
    return Object.assign({}, state, {
      loading: true,
      success: null,
      error: null,
      countryList: null,
    });
  },
  [COUNTRY_LIST_SUCCESS](state, action) {
    return Object.assign({}, state, {
      loading: false,
      success: null,
      error: null,
      countryList: action.payload,
    });
  },
  [COUNTRY_LIST_ERROR](state, action) {
    return Object.assign({}, state, {
      loading: false,
      countryList: null,
      success: null,
      error: action.payload,
    });
  },
  [CITY_LIST_LOADING](state, action) {
    return Object.assign({}, state, {
      loading: false,
      success: null,
      error: null,
      cityList: null,
    });
  },
  [CITY_LIST_SUCCESS](state, action) {
    return Object.assign({}, state, {
      loading: false,
      success: null,
      error: null,
      cityList: action.payload,
    });
  },
  [CITY_LIST_ERROR](state, action) {
    return Object.assign({}, state, {
      loading: false,
      cityList: null,
      success: null,
      error: action.payload,
    });
  },
  [CHECK_PARTICIPANT_ID_LOADING](state, action) {
    return Object.assign({}, state, {
      loading: false,
      success: null,
      error: null,
      isPatricipantIdExist: false,
    });
  },
  [CHECK_PARTICIPANT_ID_SUCCESS](state, action) {
    return Object.assign({}, state, {
      loading: false,
      success: null,
      error: null,
      isPatricipantIdExist: action.payload.status === 0 ? true : false,
    });
  },
  [CHECK_PARTICIPANT_ID_ERROR](state, action) {
    return Object.assign({}, state, {
      loading: false,
      cityList: null,
      success: null,
      isPatricipantIdExist: false,
    });
  },
  [CHECK_PHONE_NUMBER_LOADING](state, action) {
    return Object.assign({}, state, {
      loading: false,
      success: null,
      error: null,
      isPhoneNumberExist: false,
    });
  },
  [CHECK_PHONE_NUMBER_SUCCESS](state, action) {
    return Object.assign({}, state, {
      loading: false,
      success: null,
      error: null,
      isPhoneNumberExist: action.payload.status === 0 ? true : false,
    });
  },
  [CHECK_PHONE_NUMBER_ERROR](state, action) {
    return Object.assign({}, state, {
      loading: false,
      cityList: null,
      success: null,
      isPhoneNumberExist: false,
    });
  },
  [CHECK_EMAIL_ID_LOADING](state, action) {
    return Object.assign({}, state, {
      loading: false,
      success: null,
      error: null,
      isEmailIdExist: false,
    });
  },
  [CHECK_EMAIL_ID_SUCCESS](state, action) {
    return Object.assign({}, state, {
      loading: false,
      success: null,
      error: null,
      isEmailIdExist: action.payload.status === 0 ? true : false,
    });
  },
  [CHECK_EMAIL_ID_ERROR](state, action) {
    return Object.assign({}, state, {
      loading: false,
      cityList: null,
      success: null,
      isEmailIdExist: false,
    });
  },
  [FILTER_DATA_LIST_LOADING](state, action) {
    return Object.assign({}, state, {
      loading: false,
      success: null,
      error: null,
      filteredData: null,
    });
  },
  [FILTER_DATA_LIST_SUCCESS](state, action) {
    return Object.assign({}, state, {
      loading: false,
      success: null,
      error: null,
      filteredData: action.payload,
    });
  },
  [FILTER_DATA_LIST_ERROR](state, action) {
    return Object.assign({}, state, {
      loading: false,
      cityList: null,
      success: null,
    });
  },
  [PARTICIPANT_GROUPS_LOADING](state, action) {
    return Object.assign({}, state, {
      loading: false,
      success: null,
      error: null,
    });
  },
  [PARTICIPANT_GROUPS_SUCCESS](state, action) {
    return Object.assign({}, state, {
      loading: false,
      success: null,
      error: null,
      participantGroups: action.payload,
    });
  },
  [PARTICIPANT_GROUPS_ERROR](state, action) {
    return Object.assign({}, state, {
      loading: false,
      success: null,
      error: action.payload,
    });
  },
  [CLEAR_REDUCER_ACTION](state, action) {
    return Object.assign({}, state, {
      isEmailIdExist: false,
      isPatricipantIdExist: false,
      isPhoneNumberExist: false,
    });
  },
});
