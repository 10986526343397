import { combineReducers } from "redux";
import * as signInReducer from "../view/Login/reducer";
import * as participantReducer from "../view/participants/reducer";
import * as groupReducer from "../view/groups/reducer";
import * as groupMembersReducer from "../view/groupmembers/reducer";
import * as postReducer from "../view/posts/reducer";
import * as schedulePostReducer from "../view/schedulePost/reducer";

export default combineReducers(
  Object.assign(
    signInReducer,
    participantReducer,
    groupReducer,
    groupMembersReducer,
    postReducer,
    schedulePostReducer
  )
);
