import { put, takeLatest, call } from "redux-saga/effects";
import {
  DELETE_GROUP_MEMBER_ACTION,
  DELETE_GROUP_MEMBER_LOADING,
  DELETE_GROUP_MEMBER_SUCCESS,
  ALL_GROUP_MEMBER_LIST_LOADING,
  ALL_GROUP_MEMBER_LIST_ERROR,
  ALL_GROUP_MEMBER_LIST_SUCCESS,
  ALL_GROUP_MEMBER_LIST_ACTION,
  GROUP_MEMBER_LIST_ACTION,
  COPY_GROUP_MEMBER_LOADING,
  COPY_GROUP_MEMBER_SUCCESS,
  COPY_GROUP_MEMBER_ERROR,
  GROUP_MEMBER_LIST_LOADING,
  GROUP_MEMBER_LIST_SUCCESS,
  GROUP_MEMBER_LIST_ERROR,
  UPDATE_GROUP_MEMBER_ACTION,
  UPDATE_GROUP_MEMBER_LOADING,
  UPDATE_GROUP_MEMBER_SUCCESS,
  UPDATE_GROUP_MEMBER_ERROR,
  COPY_GROUP_MEMBER_ACTION,
  ALL_PARTICIPANT_LIST_MACTION,
  ALL_PARTICIPANT_LIST_MLOADING,
  ALL_PARTICIPANT_LIST_MSUCCESS,
  ALL_PARTICIPANT_LIST_MERROR,
} from "./types";
import Api from "./api";
import { DELETE_PARTICIPANT_ERROR } from "../participants/types";

function* getGroupsListSaga(data) {
  yield put({ type: GROUP_MEMBER_LIST_LOADING });
  try {
    let res = yield call(Api.getGroupsList, data.payload);
    yield put({ type: GROUP_MEMBER_LIST_SUCCESS, payload: res });
  } catch (error) {
    yield put({ type: GROUP_MEMBER_LIST_ERROR, payload: error.message });
  }
}

function* getParticipantsListSaga(data) {
  yield put({ type: ALL_PARTICIPANT_LIST_MLOADING });
  try {
    let res = yield call(Api.getAllParticipantsList, data.payload);
    yield put({ type: ALL_PARTICIPANT_LIST_MSUCCESS, payload: res });
  } catch (error) {
    yield put({ type: ALL_PARTICIPANT_LIST_MERROR, payload: error.message });
  }
}

function* getAllGroupsListSaga(data) {
  yield put({ type: ALL_GROUP_MEMBER_LIST_LOADING });
  try {
    let res = yield call(Api.getAllGroupsList, data.payload);
    yield put({ type: ALL_GROUP_MEMBER_LIST_SUCCESS, payload: res });
  } catch (error) {
    yield put({ type: ALL_GROUP_MEMBER_LIST_ERROR, payload: error.message });
  }
}

function* copyMemberSaga(data) {
  yield put({ type: COPY_GROUP_MEMBER_LOADING });
  try {
    let res = yield call(Api.copyMember, data.payload);

    yield put({ type: COPY_GROUP_MEMBER_SUCCESS, payload: res });
  } catch (error) {
    yield put({ type: COPY_GROUP_MEMBER_ERROR, payload: error.message });
  }
}

function* updateGroupMemberSaga(data) {
  yield put({ type: UPDATE_GROUP_MEMBER_LOADING });
  try {
    let res = yield call(Api.updateMember, data.payload);

    yield put({ type: UPDATE_GROUP_MEMBER_SUCCESS, payload: res });
  } catch (error) {
    yield put({ type: UPDATE_GROUP_MEMBER_ERROR, payload: error.message });
  }
}

function* deleteGroupMemberSaga(data) {
  yield put({ type: DELETE_GROUP_MEMBER_LOADING });
  try {
    let res = yield call(Api.deleteMember, data.payload);

    yield put({ type: DELETE_GROUP_MEMBER_SUCCESS, payload: res });
  } catch (error) {
    yield put({ type: DELETE_PARTICIPANT_ERROR, payload: error.message });
  }
}

function* watchCountryAsync() {
  yield takeLatest(GROUP_MEMBER_LIST_ACTION, getGroupsListSaga);
  yield takeLatest(ALL_PARTICIPANT_LIST_MACTION, getParticipantsListSaga);
  yield takeLatest(ALL_GROUP_MEMBER_LIST_ACTION, getAllGroupsListSaga);
  yield takeLatest(COPY_GROUP_MEMBER_ACTION, copyMemberSaga);
  yield takeLatest(UPDATE_GROUP_MEMBER_ACTION, updateGroupMemberSaga);
  yield takeLatest(DELETE_GROUP_MEMBER_ACTION, deleteGroupMemberSaga);

}

export default watchCountryAsync;
