import coreApi from "../../lib/coreApi";

export default {
  schedulePost: (data) => {
    let url = `/post/schedule`;
    let result = coreApi.POST(url, data);
    return result;
  },
  schedulePostList: (data) => {
    let qp = "";
    for (var key in data) {
      if (data.hasOwnProperty(key)) {
        qp = qp + `&${key}=${data[key]}`;
      }
    }
    qp = qp.replace(qp.charAt(0), "?");

    let url = `/post/schedule/list${qp}`;
    let result = coreApi.GET(url);
    return result;
  },
  schedulePostUpdate: (data) => {
    let url = `/post/schedule/update`;
    let result = coreApi.PUT(url, data);
    return result;
  },
  schedulePostUploadFile: (data) => {
    let url = `/post/schedule/uploadfiles`;
    let result = coreApi.FILE_UPLOAD(url, data, "POST");
    return result;
  },
};
