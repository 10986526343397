import { put, takeLatest, call } from "redux-saga/effects";
import {
  ALL_PARTICIPANT_LIST_POST_ACTION,
  ALL_PARTICIPANT_LIST_POST_LOADING,
  ALL_PARTICIPANT_LIST_POST_SUCCESS,
  ALL_PARTICIPANT_LIST_POST_ERROR,
  ALL_GROUP_LIST_POST_LOADING,
  ALL_GROUP_LIST_POST_ERROR,
  ALL_GROUP_LIST_POST_SUCCESS,
  ALL_GROUP_LIST_POST_ACTION,
  POST_LIST_ACTION,
  POST_LIST_LOADING,
  POST_LIST_SUCCESS,
  POST_LIST_ERROR,
  CHANGE_POST_STATUS_ACTION,
  CHANGE_POST_STATUS_LOADING,
  CHANGE_POST_STATUS_SUCCESS,
  CHANGE_POST_STATUS_ERROR,
  DELETE_POST_ACTION,
  DELETE_POST_LOADING,
  DELETE_POST_SUCCESS,
  DELETE_POST_ERROR,
} from "./types";
import Api from "./api";

function* getParticipantsListSaga(data) {
  yield put({ type: ALL_PARTICIPANT_LIST_POST_LOADING });
  try {
    let res = yield call(Api.getAllParticipantsList, data.payload);
    yield put({ type: ALL_PARTICIPANT_LIST_POST_SUCCESS, payload: res });
  } catch (error) {
    yield put({ type: ALL_PARTICIPANT_LIST_POST_ERROR, payload: error.message });
  }
}

function* getAllGroupsListSaga(data) {
  yield put({ type: ALL_GROUP_LIST_POST_LOADING });
  try {
    let res = yield call(Api.getAllGroupsList, data.payload);
    yield put({ type: ALL_GROUP_LIST_POST_SUCCESS, payload: res });
  } catch (error) {
    yield put({ type: ALL_GROUP_LIST_POST_ERROR, payload: error.message });
  }
}


function* getPostsListSaga(data) {
  yield put({ type: POST_LIST_LOADING });
  try {
    let res = yield call(Api.getPostsList, data.payload);
    yield put({ type: POST_LIST_SUCCESS, payload: res });
  } catch (error) {
    yield put({ type: POST_LIST_ERROR, payload: error.message });
  }
}

function* changePostStatusSaga(data) {
  yield put({ type: CHANGE_POST_STATUS_LOADING });
  try {
    let res = yield call(Api.changePostStatus, data.payload);
    yield put({ type: CHANGE_POST_STATUS_SUCCESS, payload: res });
  } catch (error) {
    yield put({ type: CHANGE_POST_STATUS_ERROR, payload: error.message });
  }
}

function* deletePostSaga(data) {
  yield put({ type: DELETE_POST_LOADING });
  try {
    let res = yield call(Api.deletePost, data.payload);
    yield put({ type: DELETE_POST_SUCCESS, payload: res });
  } catch (error) {
    yield put({ type: DELETE_POST_ERROR, payload: error.message });
  }
}

function* watchPostAsync() {
  yield takeLatest(ALL_PARTICIPANT_LIST_POST_ACTION, getParticipantsListSaga);
  yield takeLatest(ALL_GROUP_LIST_POST_ACTION, getAllGroupsListSaga);
  
  yield takeLatest(POST_LIST_ACTION, getPostsListSaga);
  yield takeLatest(CHANGE_POST_STATUS_ACTION, changePostStatusSaga);
  yield takeLatest(DELETE_POST_ACTION, deletePostSaga);
}

export default watchPostAsync;
