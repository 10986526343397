import coreApi from "../../lib/coreApi";

export default {
  addGroup: (data) => {
    let url = `/group/add`;
    let result = coreApi.POST(url, data);
    return result;
  },
  getGroupsList: (data) => {
    let url = `/group/list`;
    let result = coreApi.PUT(url, data);
    return result;
  },
  getAllParticipantsList: (data) => {
    let url = `/group/allparticipants/list`;
    let result = coreApi.PUT(url, data);
    return result;
  },
  getAllGroupsList: (data) => {
    let url = `/group/list/all`;
    let result = coreApi.GET(url);
    return result;
  },
  editGroup: (data) => {
    let url = `/group/edit`;
    let result = coreApi.PUT(url, data);
    return result;
  },
  deleteGroup: (data) => {
    let url = `/group/delete`;
    let result = coreApi.PATCH(url, data);
    return result;
  },
  copyMember: (data) => {
    let url = `/group/movemembers`;
    let result = coreApi.PUT(url, data);
    return result;
  },
  getGroup: (id) => {
    let url = `/group/get/${id}`;
    let result = coreApi.GET(url, id);
    return result;
  },
  sendGlobalNotification: (data) => {
    let url = `/group/send/notification`;
    let result = coreApi.PUT(url, data);
    return result;
  },
};
