export const ALL_PARTICIPANT_LIST_POST_ACTION = "ALL_PARTICIPANT_LIST_POST_ACTION";
export const ALL_PARTICIPANT_LIST_POST_LOADING = "ALL_PARTICIPANT_LIST_POST_LOADING";
export const ALL_PARTICIPANT_LIST_POST_SUCCESS = "ALL_PARTICIPANT_LIST_POST_SUCCESS";
export const ALL_PARTICIPANT_LIST_POST_ERROR = "ALL_PARTICIPANT_LIST_POST_ERROR";

export const POST_LIST_ACTION = "POST_LIST_ACTION";
export const POST_LIST_LOADING = "POST_LIST_LOADING";
export const POST_LIST_SUCCESS = "POST_LIST_SUCCESS";
export const POST_LIST_ERROR = "POST_LIST_ERROR";

export const CHANGE_POST_STATUS_ACTION = "CHANGE_POST_STATUS_ACTION";
export const CHANGE_POST_STATUS_LOADING = "CHANGE_POST_STATUS_LOADING";
export const CHANGE_POST_STATUS_SUCCESS = "CHANGE_POST_STATUS_SUCCESS";
export const CHANGE_POST_STATUS_ERROR = "CHANGE_POST_STATUS_ERROR";

export const DELETE_POST_ACTION = "DELETE_POST_ACTION";
export const DELETE_POST_LOADING = "DELETE_POST_LOADING";
export const DELETE_POST_SUCCESS = "DELETE_POST_SUCCESS";
export const DELETE_POST_ERROR = "DELETE_POST_ERROR";

export const ALL_GROUP_LIST_POST_ACTION = "ALL_GROUP_LIST_POST_ACTION";
export const ALL_GROUP_LIST_POST_LOADING = "ALL_GROUP_LIST_POST_LOADING";
export const ALL_GROUP_LIST_POST_SUCCESS = "ALL_GROUP_LIST_POST_SUCCESS";
export const ALL_GROUP_LIST_POST_ERROR = "ALL_GROUP_LIST_POST_ERROR";

export const CLEAR_POST_REDUCER = "CLEAR_POST_REDUCER";