export const SCHEDULE_POST_ACTION = "SCHEDULE_POST_ACTION";
export const SCHEDULE_POST_LOADING = "SCHEDULE_POST_LOADING";
export const SCHEDULE_POST_SUCCESS = "SCHEDULE_POST_SUCCESS";
export const SCHEDULE_POST_ERROR = "SCHEDULE_POST_ERROR";

export const SCHEDULE_POST_LIST_ACTION = "SCHEDULE_POST_LIST_ACTION";
export const SCHEDULE_POST_LIST_LOADING = "SCHEDULE_POST_LIST_LOADING";
export const SCHEDULE_POST_LIST_SUCCESS = "SCHEDULE_POST_LIST_SUCCESS";
export const SCHEDULE_POST_LIST_ERROR = "SCHEDULE_POST_LIST_ERROR";

export const SCHEDULE_POST_UPDATE_ACTION = "SCHEDULE_POST_UPDATE_ACTION";
export const SCHEDULE_POST_UPDATE_LOADING = "SCHEDULE_POST_UPDATE_LOADING";
export const SCHEDULE_POST_UPDATE_SUCCESS = "SCHEDULE_POST_UPDATE_SUCCESS";
export const SCHEDULE_POST_UPDATE_ERROR = "SCHEDULE_POST_UPDATE_ERROR";

export const SCHEDULE_POST_UPLOAD_FILE_ACTION =
  "SCHEDULE_POST_UPLOAD_FILE_ACTION";
export const SCHEDULE_POST_UPLOAD_FILE_LOADING =
  "SCHEDULE_POST_UPLOAD_FILE_LOADING";
export const SCHEDULE_POST_UPLOAD_FILE_SUCCESS =
  "SCHEDULE_POST_UPLOAD_FILE_SUCCESS";
export const SCHEDULE_POST_UPLOAD_FILE_ERROR =
  "SCHEDULE_POST_UPLOAD_FILE_ERROR";
