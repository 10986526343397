import { put, takeLatest, call } from "redux-saga/effects";
import {
  ADD_PARTICIPANT_ACTION,
  ADD_PARTICIPANT_LOADING,
  PARTICIPANT_LIST_LOADING,
  PARTICIPANT_LIST_SUCCESS,
  PARTICIPANT_LIST_ERROR,
  PARTICIPANT_LIST_ACTION,
  EDIT_PARTICIPANT_ACTION,
  EDIT_PARTICIPANT_LOADING,
  EDIT_PARTICIPANT_SUCCESS,
  EDIT_PARTICIPANT_ERROR,
  DELETE_PARTICIPANT_ACTION,
  DELETE_PARTICIPANT_ERROR,
  DELETE_PARTICIPANT_LOADING,
  DELETE_PARTICIPANT_SUCCESS,
  ADD_PARTICIPANT_SUCCESS,
  ADD_PARTICIPANT_ERROR,
  COUNTRY_LIST_LOADING,
  COUNTRY_LIST_ACTION,
  COUNTRY_LIST_SUCCESS,
  COUNTRY_LIST_ERROR,
  CITY_LIST_LOADING,
  CITY_LIST_SUCCESS,
  CITY_LIST_ERROR,
  CITY_LIST_ACTION,
  ADD_MULTIPLE_PARTICIPANT_LOADING,
  ADD_MULTIPLE_PARTICIPANT_SUCCESS,
  ADD_MULTIPLE_PARTICIPANT_ERROR,
  ADD_MULTIPLE_PARTICIPANT_ACTION,
  FILE_UPLOAD_ACTION,
  FILE_UPLOAD_LOADING,
  FILE_UPLOAD_SUCCESS,
  FILE_UPLOAD_ERROR,
  CHECK_PARTICIPANT_ID_ACTION,
  CHECK_PARTICIPANT_ID_LOADING,
  CHECK_PARTICIPANT_ID_SUCCESS,
  CHECK_PARTICIPANT_ID_ERROR,
  GET_PARTICIPANT_ACTION,
  GET_PARTICIPANT_LOADING,
  GET_PARTICIPANT_SUCCESS,
  GET_PARTICIPANT_ERROR,
  CHECK_PHONE_NUMBER_LOADING,
  CHECK_PHONE_NUMBER_SUCCESS,
  CHECK_PHONE_NUMBER_ERROR,
  CHECK_EMAIL_ID_LOADING,
  CHECK_EMAIL_ID_SUCCESS,
  CHECK_EMAIL_ID_ERROR,
  CHECK_PHONE_NUMBER_ACTION,
  CHECK_EMAIL_ID_ACTION,
  FILE_EXPORT_ACTION,
  FILE_EXPORT_LOADING,
  FILE_EXPORT_SUCCESS,
  FILE_EXPORT_ERROR,
  FILTER_DATA_LIST_LOADING,
  FILTER_DATA_LIST_SUCCESS,
  FILTER_DATA_LIST_ERROR,
  FILTER_DATA_LIST_ACTION,
  PARTICIPANT_GROUPS_LOADING,
  PARTICIPANT_GROUPS_SUCCESS,
  PARTICIPANT_GROUPS_ERROR,
  PARTICIPANT_GROUPS_ACTION
} from "./types";
import Api from "./api";

function* addParticipantSaga(data) {
  yield put({ type: ADD_PARTICIPANT_LOADING });
  try {
    let res = yield call(Api.addParticipant, data.payload);

    yield put({ type: ADD_PARTICIPANT_SUCCESS, payload: res });
  } catch (error) {
    yield put({ type: ADD_PARTICIPANT_ERROR, payload: error.message });
  }
}

function* addMultipleParticipantSaga(data) {
  yield put({ type: ADD_MULTIPLE_PARTICIPANT_LOADING });
  try {
    let res = yield call(Api.addMultipleParticipant, data.payload);

    yield put({ type: ADD_MULTIPLE_PARTICIPANT_SUCCESS, payload: res });
  } catch (error) {
    yield put({ type: ADD_MULTIPLE_PARTICIPANT_ERROR, payload: error.message });
  }
}

function* getParticipantsListSaga(data) {
  yield put({ type: PARTICIPANT_LIST_LOADING });
  try {
    let res = yield call(Api.getParticipantsList, data.payload);
    yield put({ type: PARTICIPANT_LIST_SUCCESS, payload: res });
  } catch (error) {
    yield put({ type: PARTICIPANT_LIST_ERROR, payload: error.message });
  }
}

function* editParticipantSaga(data) {
  yield put({ type: EDIT_PARTICIPANT_LOADING });
  try {
    let res = yield call(Api.editParticipant, data.payload);

    yield put({ type: EDIT_PARTICIPANT_SUCCESS, payload: res });
  } catch (error) {
    yield put({ type: EDIT_PARTICIPANT_ERROR, payload: error.message });
  }
}

function* deleteParticipantSaga(data) {
  yield put({ type: DELETE_PARTICIPANT_LOADING });
  try {
    let res = yield call(Api.deleteParticipant, data.payload);

    yield put({ type: DELETE_PARTICIPANT_SUCCESS, payload: res });
  } catch (error) {
    yield put({ type: DELETE_PARTICIPANT_ERROR, payload: error.message });
  }
}

function* getCountryListSaga() {
  yield put({ type: COUNTRY_LIST_LOADING });
  try {
    let res = yield call(Api.getCountryList,);
    yield put({ type: COUNTRY_LIST_SUCCESS, payload: res });
  } catch (error) {
    yield put({ type: COUNTRY_LIST_ERROR, payload: error.message });
  }
}

function* getCityListSaga(data) {
  yield put({ type: CITY_LIST_LOADING });
  try {
    let res = yield call(Api.getCityList, data.payload);
    yield put({ type: CITY_LIST_SUCCESS, payload: res });
  } catch (error) {
    yield put({ type: CITY_LIST_ERROR, payload: error.message });
  }
}

function* fileUploadSaga(data) {
  yield put({ type: FILE_UPLOAD_LOADING });
  try {
    let res = yield call(Api.fileUpload, data.payload);
    if(res.message.includes("records"))
    res["fileUploadError"] = true;
    yield put({ type: FILE_UPLOAD_SUCCESS, payload: res });
  } catch (error) {
    yield put({ type: FILE_UPLOAD_ERROR, payload: error.message });
  }
}

function* fileExportSaga() {
  yield put({ type: FILE_EXPORT_LOADING });
  try {
    let res = yield call(Api.fileExport);
    yield put({ type: FILE_EXPORT_SUCCESS, payload: res });
  } catch (error) {
    yield put({ type: FILE_EXPORT_ERROR, payload: error.message });
  }
}

function* checkParticipantIdSaga(data) {
  yield put({ type: CHECK_PARTICIPANT_ID_LOADING });
  try {
    let res = yield call(Api.checkParticipantId, data.payload);
    yield put({ type: CHECK_PARTICIPANT_ID_SUCCESS, payload: res });
  } catch (error) {
    yield put({ type: CHECK_PARTICIPANT_ID_ERROR, payload: error.message });
  }
}

function* checkPhoneNumberSaga(data) {
  yield put({ type: CHECK_PHONE_NUMBER_LOADING });
  try {
    let res = yield call(Api.checkPhoneNumber, data.payload);
    yield put({ type: CHECK_PHONE_NUMBER_SUCCESS, payload: res });
  } catch (error) {
    yield put({ type: CHECK_PHONE_NUMBER_ERROR, payload: error.message });
  }
}

function* checkEmailIdSaga(data) {
  yield put({ type: CHECK_EMAIL_ID_LOADING });
  try {
    let res = yield call(Api.checkEmailId, data.payload);
    yield put({ type: CHECK_EMAIL_ID_SUCCESS, payload: res });
  } catch (error) {
    yield put({ type: CHECK_EMAIL_ID_ERROR, payload: error.message });
  }
}

function* getParticipantSaga(data) {
  yield put({ type: GET_PARTICIPANT_LOADING });
  try {
    let res = yield call(Api.getParticipant, data.payload);
    yield put({ type: GET_PARTICIPANT_SUCCESS, payload: res });
  } catch (error) {
    yield put({ type: GET_PARTICIPANT_ERROR, payload: error.message });
  }
}

function* getParticipantGroupsSaga(data) {
  yield put({ type: PARTICIPANT_GROUPS_LOADING });
  try {
    let res = yield call(Api.getParticipantGroups, data.payload);
    yield put({ type: PARTICIPANT_GROUPS_SUCCESS, payload: res });
  } catch (error) {
    yield put({ type: PARTICIPANT_GROUPS_ERROR, payload: error.message });
  }
}

function* getFilterDataSaga(data) {
  yield put({ type: FILTER_DATA_LIST_LOADING });
  try {
    let res = yield call(Api.getFilterData, data.payload);
    yield put({ type: FILTER_DATA_LIST_SUCCESS, payload: res });
  } catch (error) {
    yield put({ type: FILTER_DATA_LIST_ERROR, payload: error.message });
  }
}

function* watchParticipantAsync() {
  yield takeLatest(ADD_PARTICIPANT_ACTION, addParticipantSaga);
  yield takeLatest(PARTICIPANT_LIST_ACTION, getParticipantsListSaga);
  yield takeLatest(EDIT_PARTICIPANT_ACTION, editParticipantSaga);
  yield takeLatest(DELETE_PARTICIPANT_ACTION, deleteParticipantSaga);
  yield takeLatest(COUNTRY_LIST_ACTION, getCountryListSaga);
  yield takeLatest(CITY_LIST_ACTION, getCityListSaga);
  yield takeLatest(ADD_MULTIPLE_PARTICIPANT_ACTION, addMultipleParticipantSaga);
  yield takeLatest(FILE_UPLOAD_ACTION, fileUploadSaga);
  yield takeLatest(FILE_EXPORT_ACTION, fileExportSaga);
  yield takeLatest(CHECK_PARTICIPANT_ID_ACTION, checkParticipantIdSaga);
  yield takeLatest(CHECK_PHONE_NUMBER_ACTION, checkPhoneNumberSaga);
  yield takeLatest(CHECK_EMAIL_ID_ACTION, checkEmailIdSaga);
  yield takeLatest(GET_PARTICIPANT_ACTION, getParticipantSaga);
  yield takeLatest(PARTICIPANT_GROUPS_ACTION, getParticipantGroupsSaga);
  yield takeLatest(FILTER_DATA_LIST_ACTION, getFilterDataSaga);
}

export default watchParticipantAsync;
