import { fork } from "redux-saga/effects";
import watchSignInAsync from "../view/Login/saga";
import watchParticipantAsync from "../view/participants/saga";
import watchGroupAsync from "../view/groups/saga";
import watchGroupMembersAsync from "../view/groupmembers/saga";
import watchPostAsync from "../view/posts/saga";
import watchSchedulePostAsync from "../view/schedulePost/saga";

export function* rootSaga() {
  yield fork(watchSignInAsync);
  yield fork(watchParticipantAsync);
  yield fork(watchGroupAsync);
  yield fork(watchGroupMembersAsync);
  yield fork(watchPostAsync);
  yield fork(watchSchedulePostAsync);
}
