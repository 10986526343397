export const ADD_PARTICIPANT_ACTION = "ADD_PARTICIPANT_ACTION";
export const ADD_PARTICIPANT_LOADING = "ADD_PARTICIPANT_LOADING";
export const ADD_PARTICIPANT_SUCCESS = "ADD_PARTICIPANT_SUCCESS";
export const ADD_PARTICIPANT_ERROR = "ADD_PARTICIPANT_ERROR";

export const ADD_MULTIPLE_PARTICIPANT_ACTION = "ADD_MULTIPLE_PARTICIPANT_ACTION";
export const ADD_MULTIPLE_PARTICIPANT_LOADING = "ADD_MULTIPLE_PARTICIPANT_LOADING";
export const ADD_MULTIPLE_PARTICIPANT_SUCCESS = "ADD_MULTIPLE_PARTICIPANT_SUCCESS";
export const ADD_MULTIPLE_PARTICIPANT_ERROR = "ADD_MULTIPLE_PARTICIPANT_ERROR";

export const PARTICIPANT_LIST_ACTION = "PARTICIPANT_LIST_ACTION";
export const PARTICIPANT_LIST_LOADING = "PARTICIPANT_LIST_LOADING";
export const PARTICIPANT_LIST_SUCCESS = "PARTICIPANT_LIST_SUCCESS";
export const PARTICIPANT_LIST_ERROR = "PARTICIPANT_LIST_ERROR";

export const EDIT_PARTICIPANT_ACTION = "EDIT_PARTICIPANT_ACTION";
export const EDIT_PARTICIPANT_LOADING = "EDIT_PARTICIPANT_LOADING";
export const EDIT_PARTICIPANT_SUCCESS = "EDIT_PARTICIPANT_SUCCESS";
export const EDIT_PARTICIPANT_ERROR = "EDIT_PARTICIPANT_ERROR";

export const GET_PARTICIPANT_ACTION = "GET_PARTICIPANT_ACTION";
export const GET_PARTICIPANT_LOADING = "GET_PARTICIPANT_LOADING";
export const GET_PARTICIPANT_SUCCESS = "GET_PARTICIPANT_SUCCESS";
export const GET_PARTICIPANT_ERROR = "GET_PARTICIPANT_ERROR";

export const DELETE_PARTICIPANT_ACTION = "DELETE_PARTICIPANT_ACTION";
export const DELETE_PARTICIPANT_LOADING = "DELETE_PARTICIPANT_LOADING";
export const DELETE_PARTICIPANT_SUCCESS = "DELETE_PARTICIPANT_SUCCESS";
export const DELETE_PARTICIPANT_ERROR = "DELETE_PARTICIPANT_ERROR";

export const COUNTRY_LIST_ACTION = "COUNTRY_LIST_ACTION";
export const COUNTRY_LIST_LOADING = "COUNTRY_LIST_LOADING";
export const COUNTRY_LIST_SUCCESS = "COUNTRY_LIST_SUCCESS";
export const COUNTRY_LIST_ERROR = "COUNTRY_LIST_ERROR";

export const CITY_LIST_ACTION = "CITY_LIST_ACTION";
export const CITY_LIST_LOADING = "CITY_LIST_LOADING";
export const CITY_LIST_SUCCESS = "CITY_LIST_SUCCESS";
export const CITY_LIST_ERROR = "CITY_LIST_ERROR";

export const FILE_UPLOAD_ACTION = "FILE_UPLOAD_ACTION";
export const FILE_UPLOAD_LOADING = "FILE_UPLOAD_LOADING";
export const FILE_UPLOAD_SUCCESS = "FILE_UPLOAD_SUCCESS";
export const FILE_UPLOAD_ERROR = "FILE_UPLOAD_ERROR";

export const FILE_EXPORT_ACTION = "FILE_EXPORT_ACTION";
export const FILE_EXPORT_LOADING = "FILE_EXPORT_LOADING";
export const FILE_EXPORT_SUCCESS = "FILE_EXPORT_SUCCESS";
export const FILE_EXPORT_ERROR = "FILE_EXPORT_ERROR";

export const CHECK_PARTICIPANT_ID_ACTION = "CHECK_PARTICIPANT_ID_ACTION";
export const CHECK_PARTICIPANT_ID_LOADING = "CHECK_PARTICIPANT_ID_LOADING";
export const CHECK_PARTICIPANT_ID_SUCCESS = "CHECK_PARTICIPANT_ID_SUCCESS";
export const CHECK_PARTICIPANT_ID_ERROR = "CHECK_PARTICIPANT_ID_ERROR";

export const CHECK_PHONE_NUMBER_ACTION = "CHECK_PHONE_NUMBER_ACTION";
export const CHECK_PHONE_NUMBER_LOADING = "CHECK_PHONE_NUMBER_LOADING";
export const CHECK_PHONE_NUMBER_SUCCESS = "CHECK_PHONE_NUMBER_SUCCESS";
export const CHECK_PHONE_NUMBER_ERROR = "CHECK_PHONE_NUMBER_ERROR";

export const CHECK_EMAIL_ID_ACTION = "CHECK_EMAIL_ID_ACTION";
export const CHECK_EMAIL_ID_LOADING = "CHECK_EMAIL_ID_LOADING";
export const CHECK_EMAIL_ID_SUCCESS = "CHECK_EMAIL_ID_SUCCESS";
export const CHECK_EMAIL_ID_ERROR = "CHECK_EMAIL_ID_ERROR";

export const FILTER_DATA_LIST_ACTION = "FILTER_DATA_LIST_ACTION";
export const FILTER_DATA_LIST_LOADING = "FILTER_DATA_LIST_LOADING";
export const FILTER_DATA_LIST_SUCCESS = "FILTER_DATA_LIST_SUCCESS";
export const FILTER_DATA_LIST_ERROR = "FILTER_DATA_LIST_ERROR";

export const PARTICIPANT_GROUPS_ACTION = "PARTICIPANT_GROUPS_ACTION";
export const PARTICIPANT_GROUPS_LOADING = "PARTICIPANT_GROUPS_LOADING";
export const PARTICIPANT_GROUPS_SUCCESS = "PARTICIPANT_GROUPS_SUCCESS";
export const PARTICIPANT_GROUPS_ERROR = "PARTICIPANT_GROUPS_ERROR";

export const CLEAR_REDUCER_ACTION = "CLEAR_REDUCER_ACTION";