import coreApi from "../../lib/coreApi";

export default {
  getPostsList: (data) => {
    let url = `/post/list`;
    let result = coreApi.POST(url, data);
    return result;
  },
  getAllParticipantsList: (data) => {
    let url = `/post/participant/list`;
    let result = coreApi.POST(url, data);
    return result;
  },
  getAllGroupsList: (data) => {
    let url = `/group/list/all`;
    let result = coreApi.GET(url);
    return result;
  },
  changePostStatus: (data) => {
    let url = `/post/changestatus`;
    let result = coreApi.POST(url, data);
    return result;
  },
  deletePost: (data) => {
    let url = `/post/deletepost`;
    let result = coreApi.PATCH(url, data);
    return result;
  },
};
